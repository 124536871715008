<ion-header class="ion-no-border">
  <ion-toolbar color="white">
    <ion-buttons slot="start" *ngIf="showBackBtn()">
      <ion-button (click)="back()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <!-- <ion-icon src="./assets/wedding/icon/general/flag.svg"></ion-icon> &nbsp; -->
      <ng-container *ngIf="migrate">
        {{ 'ACCOUNTS_MIGRATE.title' | translate }}
      </ng-container>
      <ng-container *ngIf="!migrate">
        <ng-container *ngIf="!premium">{{ 'ACCOUNTS.lbl.trial_setup' | translate }}</ng-container>
        <ng-container *ngIf="premium">{{ 'ACCOUNTS.lbl.premium_setup' | translate }}</ng-container>
      </ng-container>
    </ion-title>
    <!-- <ion-buttons slot="end" *ngIf="premium && !type">
      <ion-button (click)="skipSetup()">
        <ion-icon src="./assets/wedding/icon/general/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons> -->
    <ion-progress-bar [value]="progress" color="primary"></ion-progress-bar>
  </ion-toolbar>
</ion-header>
<!-- [style.height]="pageMode ? 'calc(100vh - 44px - 88px)' : '100%'" -->
<ion-content class="full-width ion-padding" #content>
  <ng-container>
    <div *ngIf="step === 'couple'">
      <div class="ion-text-center center ion-margin-bottom">
        <div class="ion-margin">
          <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.couple.title' | translate) }}</ion-text>
        </div>
        <!-- <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div>
        <div class="ion-margin">
          <ng-container *ngIf="true then imgBlock"></ng-container>
        </div> -->
      </div>
        
      <form [formGroup]="coupleForm" (ngSubmit)="coupleFormSubmit()" *ngIf="coupleForm">
        <ion-list class="background-transparent background-transparent ion-padding-vertical" lines="none">
          <ng-container *ngIf="!eventMode">
            <ion-item class="full-width background-transparent ion-item-no-padding" lines="none">
              <ion-avatar slot="start" class="small-avatar" (click)="selectAvatarPhoto(1)">
                <ion-img [src]="coupleForm.value.couple1Avatar.url" *ngIf="coupleForm?.value?.couple1Avatar?.url"></ion-img>
                <ion-icon color="medium" src="./assets/wedding/img/avatar/blank.svg" *ngIf="!coupleForm?.value?.couple1Avatar?.url"></ion-icon>
              </ion-avatar>
              <ion-text class="full-width">
                <ion-item class="full-width background-transparent ion-item-no-padding" lines="none">
                  <ion-label position="stacked" color="dark">
                    {{ 'FIELD.name' | translate: { field: ('LIST.wedding_couple_type.' + getCoupleType(1) | translate) } }}<ion-text color="danger">*</ion-text>
                    <span class="font-underline ion-float-right" (click)="presentCoupleTypePicker(1)">{{ 'CRUD.edit' | translate }}</span>
                  </ion-label>
                  <ion-input formControlName="couple1" type="text" inputmode="text" autocapitalize="words" autocomplete="name" class="prompt-input" (ionBlur)="setupAccountTitle()" tabindex="1"></ion-input>
                </ion-item>
                <div class="error-div">
                  <ng-container *ngFor="let validation of validationMsg?.couple1">
                    <div class="error-message" *ngIf="coupleForm.get('couple1').hasError(validation.type) && (coupleForm.get('couple1').dirty || coupleForm.get('couple1').touched)">
                      <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
                    </div>
                  </ng-container>
                </div>
              </ion-text>
            </ion-item>

            <ion-item class="full-width background-transparent ion-item-no-padding" lines="none">
              <ion-avatar slot="start" class="small-avatar" (click)="selectAvatarPhoto(2)">
                <ion-img [src]="coupleForm.value.couple2Avatar.url" *ngIf="coupleForm?.value?.couple2Avatar?.url"></ion-img>
                <ion-icon color="medium" src="./assets/wedding/img/avatar/blank.svg" *ngIf="!coupleForm?.value?.couple2Avatar?.url"></ion-icon>
              </ion-avatar>
              <ion-text class="full-width">
                <ion-item class="full-width background-transparent ion-item-no-padding" lines="none">
                  <ion-label position="stacked" color="dark">
                    {{ 'FIELD.name' | translate: { field: ('LIST.wedding_couple_type.' + getCoupleType(2) | translate) } }} <ion-text color="danger">*</ion-text>
                    <span class="font-underline ion-float-right" (click)="presentCoupleTypePicker(2)">{{ 'CRUD.edit' | translate }}</span>
                  </ion-label>
                  <ion-input formControlName="couple2" type="text" inputmode="text" autocapitalize="words" autocomplete="name" class="prompt-input" (ionBlur)="setupAccountTitle()" tabindex="2"></ion-input>
                </ion-item>
                <div class="error-div">
                  <ng-container *ngFor="let validation of validationMsg?.couple2">
                    <div class="error-message" *ngIf="coupleForm.get('couple2').hasError(validation.type) && (coupleForm.get('couple2').dirty || coupleForm.get('couple2').touched)">
                      <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
                    </div>
                  </ng-container>
                </div>
              </ion-text>
            </ion-item>
          </ng-container>
  
          <ng-container *ngIf="eventMode">
            <ion-item class="full-width background-transparent ion-item-no-padding" lines="none">
              <ion-avatar slot="start" class="small-avatar" (click)="selectAvatarPhoto(0)">
                <ion-img [src]="coupleForm.value.organizerAvatar.url" *ngIf="coupleForm?.value?.organizerAvatar?.url"></ion-img>
                <ion-icon color="medium" src="./assets/wedding/img/avatar/blank.svg" *ngIf="!coupleForm?.value?.organizerAvatar?.url"></ion-icon>
              </ion-avatar>
              <ion-text class="full-width">
                <ion-item class="full-width background-transparent ion-item-no-padding" lines="none">
                  <ion-label position="stacked" color="dark">{{ replaceEventType('FOR_EVENT.lbl.organizer' | translate) }} <ion-text color="danger">*</ion-text></ion-label>
                  <ion-input formControlName="organizer" type="text" inputmode="text" autocapitalize="words" autocomplete="on" class="prompt-input" tabindex="1"></ion-input>
                </ion-item>
                <div class="error-div">
                  <ng-container *ngFor="let validation of validationMsg?.organizer">
                    <div class="error-message" *ngIf="coupleForm.get('organizer').hasError(validation.type) && (coupleForm.get('organizer').dirty || coupleForm.get('organizer').touched)">
                      <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType(validation.msg) }}
                    </div>
                  </ng-container>
                </div>
              </ion-text>
            </ion-item>
          </ng-container>
  
          <ng-container *ngIf="(coupleForm.value.couple1 && coupleForm.value.couple2) || coupleForm.value.title || eventMode">
            <ion-item class="background-transparent ion-item-no-padding">
              <ion-label position="stacked" color="dark">{{ replaceEventType('ACCOUNT.lbl.wedding_title' | translate) }} <ion-text color="danger">*</ion-text></ion-label>
              <ion-input formControlName="title" type="text" inputmode="text" autocapitalize="words" autocomplete="on" class="prompt-input" (ionChange)="setupCustomAccountTitle()" (ionBlur)="setupAccountTitle()" tabindex="3"></ion-input>
            </ion-item>
            <div class="error-div">
              <ng-container *ngFor="let validation of validationMsg?.title">
                <div class="error-message" *ngIf="coupleForm.get('title').hasError(validation.type) && (coupleForm.get('title').dirty || coupleForm.get('title').touched)">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType(validation.msg) }}
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ion-list>
      </form>
  
      <app-account-couple-type class="account-couple-type full-width-height" [showEventMode]="true" [hideRole]="true" [eventMode]="eventMode" [setReady]="true" (outputEventMode)="setupEventMode($event)"></app-account-couple-type>
    </div>
  
    <div class="ion-padding" *ngIf="step === 'role'">
      <div>
        <div class="ion-text-center center ion-margin-bottom">
          <div class="ion-margin">
            <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.role.title' | translate) }}</ion-text>
          </div>
          <!-- <div class="ion-margin">
            <ng-container *ngIf="true then changeInfoBlock"></ng-container>
          </div> -->
          <!-- <div class="ion-margin">
            <ng-container *ngIf="true then imgBlock"></ng-container>
          </div> -->
        </div>
        <div class="ion-padding-vertical">
          <ion-grid fixed>
            <ion-row class="ion-align-items-center ion-justify-content-center ion-text-center">
              <ng-container *ngIf="!eventMode">
                <ng-container *ngFor="let accountCouple of accountCoupleList; let i = index;">
                  <ion-col class="ion-text-center center ion-align-self-center" *ngIf="accountCouple?.coupleId && (i === 0 || !checkSameCoupleType())">
                    <ion-card class="role-card vertical-center center font-14 ion-padding ion-no-margin" [color]="accountCouple.coupleType === role?.type && !role?.custom ? 'tertiary' : 'medium'" (click)="selectCoupleRole(accountCouple)" button>
                      {{ ('LIST.wedding_couple_type.' + accountCouple.coupleType) | translate }}
                    </ion-card>
                  </ion-col>
                </ng-container>
              </ng-container>
              <ion-col *ngFor="let roleObj of roleList">
                <ion-card class="role-card vertical-center center font-14 ion-padding ion-no-margin" [color]="roleObj?.role?.type === role?.type && roleObj?.role?.custom === role?.custom ? 'tertiary' : 'medium'" (click)="selectRole(roleObj?.role)" button>
                  {{ ('LIST.role.' + roleObj.role.type) | translate }}
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
          <!-- <ion-grid class="ion-no-padding" fixed>
            <ion-row class="ion-align-items-center ion-justify-content-center ion-text-center" *ngIf="!eventMode && accountCoupleList?.length">
              <ion-col class="ion-no-padding" size-xs="0" size-sm="1" size-md="2" size-lg="3"></ion-col>
              <ng-container *ngFor="let accountCouple of accountCoupleList; let i = index;">
                <ion-col class="ion-text-center center ion-align-self-center" *ngIf="accountCouple?.coupleId && (i === 0 || !checkSameCoupleType())">
                  <ion-card class="ion-padding ion-no-margin ion-text-center vertical-center couple-card" [color]="coupleId === accountCouple.coupleId ? 'tertiary': 'light'" (click)="selectCouple(accountCouple.coupleId)" button>
                    <ion-item class="ion-item-no-padding background-transparent" lines="none">
                      <ion-img class="icon-couple" slot="start" src="./assets/wedding/img/account/bride.svg" *ngIf="accountCouple.coupleType === 'bride'"></ion-img>
                      <ion-text class="font-14 font-med">{{ 'ACCOUNTS.btn.' + accountCouple.coupleType | translate }}</ion-text>
                      <ion-img class="icon-couple" slot="end" style="margin-right: 0;" src="./assets/wedding/img/account/groom.svg" *ngIf="accountCouple.coupleType === 'groom'"></ion-img>
                    </ion-item>
                  </ion-card>
                </ion-col>
              </ng-container>
              <ion-col class="ion-no-padding" size-xs="0" size-sm="1" size-md="2" size-lg="3"></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center ion-justify-content-center ion-text-center">
              <ion-col class="ion-text-center center ion-align-self-center">
                <ng-container  *ngIf="!eventMode">
                  <ion-button class="" expand="block" [fill]="coupleId === 0 ? 'solid' : 'outline'" (click)="setOtherRole()">{{ 'ACCOUNTS.btn.other_role' | translate }}</ion-button>
                </ng-container>
                <div *ngIf="coupleId === 0">
                  <ion-item class="ion-margin-vertical ion-text-center prompt-input-item" detail lines="none" (click)="presentRoleModal(role)">
                    <ion-text *ngIf="role?.type">
                      <ng-container *ngIf="role.custom">{{ role.type }}</ng-container>
                      <ng-container *ngIf="!role.custom">{{ 'LIST.role.' + role.type | translate }}</ng-container>
                    </ion-text>
                    <ion-text *ngIf="!role?.type">
                      {{ 'MSG.please_select' | translate }}
                    </ion-text>
                  </ion-item>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid> -->
          
        </div>
      </div>
    </div>
  
    <div class="ion-padding" *ngIf="step === 'photo'">
      <div class="ion-text-center center ion-margin-bottom">
        <div class="ion-margin">
          <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.photo.title' | translate) }}</ion-text>
        </div>
        <!-- <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div> -->
        <!-- <div class="ion-margin">
          <ng-container *ngIf="true then imgBlock"></ng-container>
        </div> -->
      </div>
      <ion-grid class="full-width" #gridContent fixed>
        <ion-row class="ion-justify-content-between" *ngIf="coverWidth && coverHeight">
          <ion-col class="ion-align-self-center">
            <ion-card class="theme-card box-shadow ion-text-center" [style.width.px]="coverWidth" [style.height.px]="coverHeight" color="grey" (click)="selectCover()" button>
              <div class="full-width-height vertical-center theme-img" [style.background-image]="selectedCover?.type !== 'local' && selectedCover?.url ? 'url(' + selectedCover.url + ')' : ''" [style.width.px]="coverWidth" [style.height.px]="coverHeight">
                <ng-container *ngIf="selectedCover?.url">
                  <ion-button class="selected-btn font-14" size="small" shape="round" color="primary" *ngIf="selectedCover?.url && selectedCover?.type !== 'local'">
                    <ion-icon class="font-20" slot="icon-only" src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
                  </ion-button>
                </ng-container>
                <ng-container>
                  <ion-item class="upload-item background-transparent ion-no-margin ion-item-no-padding" color="grey" lines="none">
                    <ion-label class="ion-text-center ion-margin">
                      <ion-img class="center upload-img" src="./assets/wedding/icon/general/image-outline.svg"></ion-img>
                      <div class="ion-text-center font-14">{{ 'WEBSITE.theme.cover.photo_upload' | translate }}</div>
                    </ion-label>
                  </ion-item>
                </ng-container>
              </div>
            </ion-card>
          </ion-col>
          <ion-col class="ion-align-self-center" *ngFor="let cover of coverList">
            <ion-card class="theme-card box-shadow background-transparent ion-text-center" [style.width.px]="coverWidth" [style.height.px]="coverHeight" (click)="selectCover(cover)" *ngIf="cover?.url && cover?.type === 'local'" button>
              <div class="full-width-height vertical-center theme-img" [style.background-image]="'url(' + cover.url + ')'" [style.width.px]="coverWidth" [style.height.px]="coverHeight">
                <ion-button class="selected-btn font-14" size="small" shape="round" color="primary" *ngIf="cover.url === selectedCover?.url">
                  <ion-icon class="font-20" slot="icon-only" src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
                </ion-button>
              </div>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
      <!-- <div>
        <ng-container *ngIf="getAccountGalleryList() as accountGalleryList">
          <ion-item class="full-width" color="light" lines="none" *ngIf="!accountGalleryList?.length" (click)="selectPhoto(false)" button>
            <ion-label class="ion-margin ion-padding ion-text-center">
              <ion-img class="center upload-img" src="./assets/wedding/icon/general/cloud-upload-outline.svg"></ion-img>
              <div class="ion-text-center font-14 font-semi">{{ 'VISITOR.setting.background.upload' | translate }}</div>
            </ion-label>
          </ion-item>
          <ion-item class="background-transparent full-width" lines="none" *ngIf="accountGalleryList?.length" (click)="selectPhoto(true)">
            <ion-label class="ion-no-margin custom-background-label">
              <ion-img class="custom-background" [src]="accountGalleryList[0]"></ion-img>
              <div class="ion-text-center">
                <ion-text class="font-10 font-med font-underline">{{ 'CRUD.edit' | translate }}</ion-text>
              </div>
            </ion-label>
          </ion-item>
        </ng-container>
        
      </div> -->
    </div>
  
    <div *ngIf="step === 'account'">
      <div class="ion-text-center center ion-margin-bottom">
        <div class="ion-margin">
          <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.account.title' | translate) }}</ion-text>
        </div>
        <!-- <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div>
        <div class="ion-margin">
          <ion-img class="img center" src="./assets/wedding/img/accounts/setup/account.svg"></ion-img>
        </div> -->
      </div>
      
      <form [formGroup]="accountForm" (ngSubmit)="accountFormSubmit()" *ngIf="accountForm">
        <ion-list class="background-transparent" lines="none">
          <ion-item class="background-transparent ion-item-no-padding">
            <ion-label position="stacked">
              {{ countryLbl }}
            </ion-label>
            <ion-item class="prompt-input-item" lines="none" (click)="openCountryModal()" button detail>
              {{ accountForm?.value?.country?.name ? accountForm.value.country.name : '' }}
            </ion-item>
            <div class="error-div">
              <ng-container *ngFor="let validation of validationMsg?.country">
                <div class="error-message" *ngIf="accountForm.get('country').hasError(validation.type) && (accountForm.get('country').dirty || accountForm.get('country').touched)">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType(validation.msg) }}
                </div>
              </ng-container>
            </div>    
          </ion-item>
    
          <ng-container *ngIf="accountForm.value.country && timezoneList?.length">
            <ion-item class="background-transparent ion-item-no-padding" (click)="openTimezone()">
              <ion-label position="stacked" color="dark">{{ replaceEventType('DATE.lbl.wedding_timezone' | translate) }} <ion-text color="danger">*</ion-text></ion-label>
              <ion-item class="prompt-input-item timezone-item background-transparent">
                <ion-label class="ion-no-margin" *ngIf="timezone?.label">{{ timezone.label }}</ion-label>
                <ion-note slot="end" class="ion-no-margin">
                  <ion-icon class="font-18" color="medium" src="./assets/wedding/icon/general/chevron-down.svg"></ion-icon>
                </ion-note>
              </ion-item>
              <div class="error-div">
                <div class="error-message" *ngIf="!timezone || !timezone.name">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType('VALIDATION.required' | translate: { field: 'DATE.lbl.wedding_timezone' | translate }) }}
                </div>
              </div>  
            </ion-item>
    
            <ion-item class="background-transparent ion-item-no-padding">
              <ion-label position="stacked" color="dark">{{ replaceEventType('DATE.lbl.wedding_date' | translate) }} <ion-text color="danger">*</ion-text></ion-label>
              <ion-item class="prompt-input-item background-transparent" lines="none" (click)="presentDatetimeModal('date', 'start')">
                <ion-label class="ion-no-margin" *ngIf="accountForm?.value?.date">{{ formatStartDate }}</ion-label>
              </ion-item>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.date">
                  <div class="error-message" *ngIf="accountForm.get('date').hasError(validation.type) && (accountForm.get('date').dirty || accountForm.get('date').touched)">
                    <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType(validation.msg) }}
                  </div>
                </ng-container>
              </div>
            </ion-item>
    
            <ng-container *ngIf="accountForm?.value?.date">
              <ion-item class="background-transparent ion-item-no-padding">
                <ion-label position="stacked" color="dark">{{ 'VISITOR.setting.lbl.startTime' | translate }} <ion-text color="danger">*</ion-text></ion-label>
                <ion-item class="prompt-input-item background-transparent" lines="none" (click)="presentDatetimeModal('time', 'start')">
                  <ion-label class="ion-no-margin" *ngIf="accountForm?.value?.time">{{  formatStartTime }}</ion-label>
                </ion-item>
                <div class="error-div">
                  <ng-container *ngFor="let validation of validationMsg?.time">
                    <div class="error-message" *ngIf="accountForm.get('time').hasError(validation.type) && (accountForm.get('time').dirty || accountForm.get('time').touched)">
                      <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType(validation.msg) }}
                    </div>
                  </ng-container>
                </div>
              </ion-item>
      
              <ion-item class="background-transparent ion-item-no-padding" *ngIf="accountForm?.value?.time">
                <ion-label position="stacked" color="dark">{{ 'VISITOR.setting.lbl.endTime' | translate }}</ion-label>
                <ion-item class="prompt-input-item background-transparent" lines="none" (click)="presentDatetimeModal('date-time', 'end')">
                  <ion-label class="ion-no-margin" *ngIf="accountForm?.value?.endTime">
                    <div *ngIf="formatStartDate !== formatEndDate">{{ formatEndDate }}</div>
                    <div>{{ formatEndTime }}</div>
                  </ion-label>
                </ion-item>
                <div class="error-div">
                  <ng-container *ngFor="let validation of validationMsg?.endTime">
                    <div class="error-message" *ngIf="accountForm.get('endTime').hasError(validation.type) && (accountForm.get('endTime').dirty || accountForm.get('endTime').touched)">
                      <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType(validation.msg) }}
                    </div>
                  </ng-container>
                </div>
              </ion-item>
            </ng-container>
            
          </ng-container>
        </ion-list>
      </form>
    </div>
  
    <div *ngIf="step === 'venue'">
      <div class="ion-text-center center ion-margin-bottom">
        <div class="ion-margin">
          <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.venue.title' | translate) }}</ion-text>
        </div>
        <!-- <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div>
        <div class="ion-margin">
          <ion-img class="img center" src="./assets/wedding/img/accounts/setup/venue.svg"></ion-img>
        </div> -->
      </div>

      <!-- <ion-item class="full-width background-transparent ion-item-no-padding" lines="none">
        <ion-label position="stacked" color="dark">{{ replaceEventType('LOCATION.lbl.wedding_venue' | translate) }}</ion-label>
      </ion-item> -->

      <form [formGroup]="venueForm" (ngSubmit)="venueFormSubmit()" *ngIf="venueForm && pageLocation?.name">
        <ion-list class="background-transparent" lines="none">
          <ion-item class="background-transparent ion-item-no-padding">
            <ion-label position="stacked" color="dark">{{ 'LOCATION.lbl.venue_name' | translate }}</ion-label>
            <ion-input class="font-14 prompt-input" formControlName="venue" type="text" inputmode="text" autocapitalize="words" autocomplete="on" tabindex="1"></ion-input>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.venue">
              <div class="error-message" *ngIf="venueForm.get('venue').hasError(validation.type) && (venueForm.get('venue').dirty || venueForm.get('venue').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType(validation.msg) }}
              </div>
            </ng-container>
          </div>

          <ion-item class="background-transparent ion-item-no-padding">
            <ion-label position="stacked" color="dark">{{ 'LOCATION.lbl.venue_address' | translate }}</ion-label>
            <ion-item class="prompt-input-item ion-item-no-padding full-width" lines="none">
              <div class="full-width text-area">
                <ion-textarea class="font-14 background-white" formControlName="address" autogrow="true" tabindex="2"></ion-textarea>
              </div>
            </ion-item>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.venue">
              <div class="error-message" *ngIf="venueForm.get('address').hasError(validation.type) && (venueForm.get('address').dirty || venueForm.get('address').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType(validation.msg) }}
              </div>
            </ng-container>
          </div>

          <ion-item class="background-transparent ion-item-no-padding">
            <ion-label position="stacked" color="dark">{{ 'LOCATION.lbl.venue_hall' | translate }}</ion-label>
            <ion-input class="font-14 prompt-input" formControlName="hall" type="text" inputmode="text" autocapitalize="words" autocomplete="on" tabindex="3"></ion-input>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.hall">
              <div class="error-message" *ngIf="venueForm.get('hall').hasError(validation.type) && (venueForm.get('hall').dirty || venueForm.get('hall').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ replaceEventType(validation.msg) }}
              </div>
            </ng-container>
          </div>
        </ion-list>
      </form>

      <ion-item class="ion-item-no-padding background-transparent" lines="none">
        <ion-label position="stacked" color="dark">
          {{ 'VISITOR.setting.gps.title' | translate }}
          &nbsp;<span class="font-underline ion-float-right" (click)="testGps()" *ngIf="pageLocation?.name"><ion-icon class="setting-icon icon-small vertical-middle" src="./assets/wedding/icon/general/map-point.svg"></ion-icon>&nbsp;{{ 'BTN.preview' | translate }}</span>
        </ion-label>
        <ion-item class="website-input-item" (click)="presentLocationModal()" lines="none" detail>
          <ion-text class="font-14">{{ pageLocation?.name ? ('LOCATION.btn.edit_venue' | translate) : ('LOCATION.btn.setup_venue' | translate) }}</ion-text>
        </ion-item>
      </ion-item>
      <!-- <ion-item class="full-width prompt-input-item background-transparent" lines="none" *ngIf="pageLocation?.name else locationBlock">
        <div class="ion-margin-vertical">
          <div>
            <ion-text class="font-10">{{ 'VISITOR.setting.gps.title' | translate }}</ion-text>
          </div>
          <ion-button class="gps-btn font-12" fill="solid" (click)="testGps()">
            <ion-icon class="icon-small" src="./assets/wedding/icon/general/search.svg"></ion-icon>
            <ion-label class="ion-padding-horizontal">{{ 'BTN.preview' | translate }}</ion-label>
          </ion-button>
          <ion-button class="gps-btn font-12" fill="solid" color="warning" (click)="presentLocationModal()">
            <ion-icon class="icon-small" src="./assets/wedding/icon/general/edit.svg"></ion-icon>
            <ion-label class="ion-padding-horizontal">{{ 'CRUD.edit' | translate }}</ion-label>
          </ion-button>
        </div>
      </ion-item>
      <div class="error-div"></div> -->
    </div>
    <!-- <ng-template #locationBlock>
      <div class="error-div"></div>
    </ng-template> -->
  
    <div *ngIf="step === 'category'">
      <div class="ion-text-center center ion-margin-bottom">
        <div class="ion-margin">
          <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.category.title') | translate }}</ion-text>
        </div>
        <!-- <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div>
        <div class="ion-margin">
          <ion-img class="img center" src="./assets/wedding/img/accounts/setup/setting_field.svg"></ion-img>
        </div> -->
      </div>
  
      <ion-grid class="ion-margin-vertical" fixed>
        <ion-row>
          <ion-col class="ion-no-padding">
            <ion-item class="setting-field-title-item full-width ion-item-no-padding" lines="none">
              <ion-text class="font-12" color="dark" slot="start">{{ invitedByTitle }}</ion-text>
              <ion-note class="font-12 font-underline" slot="end" (click)="resetSettingField('invited_by')">{{ 'BTN.reset' | translate }}</ion-note>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ng-container *ngFor="let invitedBy of stdInvitedByList">
            <ion-col size="auto">
              <ion-card class="setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" [color]="checkSelectedSettingField('invited_by', invitedBy) ? 'tertiary' : 'light'" (click)="clickSettingField('invited_by', invitedBy)" button>
                {{ getValue('invited_by', invitedBy) }}
              </ion-card>
            </ion-col>
          </ng-container>
          <ion-col size="auto">
            <ion-card class="setting-field-card new-setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" (click)="newSettingField('invited_by')" button>
              <div>
                <ion-icon class="new-setting-field-icon" src="./assets/wedding/icon/general/add.svg"></ion-icon> {{ 'CRUD.new' | translate }}
              </div>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
  
      <ion-grid class="ion-margin-vertical" fixed>
        <ion-row>
          <ion-col class="ion-no-padding">
            <ion-item class="setting-field-title-item full-width ion-item-no-padding" lines="none">
              <ion-text class="font-12" color="dark" slot="start">{{ categoryTitle }}</ion-text>
              <ion-note class="font-12 font-underline" slot="end" (click)="resetSettingField('category')">{{ 'BTN.reset' | translate }}</ion-note>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ng-container *ngFor="let category of stdCategoryList">
            <ion-col size="auto">
              <ion-card class="setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" [color]="checkSelectedSettingField('category', category) ? 'tertiary' : 'light'" (click)="clickSettingField('category', category)" button>
                {{ getValue('category', category) }}
              </ion-card>
            </ion-col>
          </ng-container>
          <ion-col size="auto">
            <ion-card class="setting-field-card new-setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" (click)="newSettingField('category')" button>
              <div>
                <ion-icon class="new-setting-field-icon" src="./assets/wedding/icon/general/add.svg"></ion-icon> {{ 'CRUD.new' | translate }}
              </div>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  
    <div *ngIf="step === 'dietary'">
      <div class="ion-text-center center ion-margin-bottom">
        <div class="ion-margin">
          <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.dietary.title' | translate) }}</ion-text>
        </div>
        <!-- <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div>
        <div class="ion-margin">
          <ion-img class="img center" src="./assets/wedding/img/accounts/setup/setting_field.svg"></ion-img>
        </div> -->
      </div>
  
      <ion-grid class="ion-margin-vertical" fixed>
        <ion-row>
          <ion-col class="ion-no-padding">
            <ion-item class="setting-field-title-item full-width ion-item-no-padding" lines="none">
              <ion-text class="font-12" color="dark" slot="start">{{ dietaryReqTitle }}</ion-text>
              <ion-note class="font-12 font-underline" slot="end" (click)="resetSettingField('dietary_req')">{{ 'BTN.reset' | translate }}</ion-note>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ng-container *ngFor="let dietaryReq of stdDietaryReqList">
            <ion-col size="auto">
              <ion-card class="setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" [color]="checkSelectedSettingField('dietary_req', dietaryReq) ? 'tertiary' : 'light'" (click)="clickSettingField('dietary_req', dietaryReq)" button>
                {{ getValue('dietary_req', dietaryReq) }}
              </ion-card>
            </ion-col>
          </ng-container>
          <ion-col size="auto">
            <ion-card class="setting-field-card new-setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" (click)="newSettingField('dietary_req')" button>
              <div>
                <ion-icon class="new-setting-field-icon" src="./assets/wedding/icon/general/add.svg"></ion-icon> {{ 'CRUD.new' | translate }}
              </div>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
  
      <ion-grid class="ion-margin-vertical" fixed>
        <ion-row>
          <ion-col class="ion-no-padding">
            <ion-item class="setting-field-title-item full-width ion-item-no-padding" lines="none">
              <ion-text class="font-12" color="dark" slot="start">{{ specialReqTitle }}</ion-text>
              <ion-note class="font-12 font-underline" slot="end" (click)="resetSettingField('special_req')">{{ 'BTN.reset' | translate }}</ion-note>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ng-container *ngFor="let specialReq of stdSpecialReqList">
            <ion-col size="auto">
              <ion-card class="setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" [color]="checkSelectedSettingField('special_req', specialReq) ? 'tertiary' : 'light'" (click)="clickSettingField('special_req', specialReq)" button>
                {{ getValue('special_req', specialReq) }}
              </ion-card>
            </ion-col>
          </ng-container>
          <ion-col size="auto">
            <ion-card class="setting-field-card new-setting-field-card vertical-center ion-text-center ion-text-wrap ion-no-margin" (click)="newSettingField('special_req')" button>
              <div>
                <ion-icon class="new-setting-field-icon" src="./assets/wedding/icon/general/add.svg"></ion-icon> {{ 'CRUD.new' | translate }}
              </div>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  
    <div class="ion-margin" *ngIf="step === 'seating'">
      <div class="ion-text-center center ion-margin-bottom">
        <div class="ion-margin">
          <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('ACCOUNTS.setup.seating.title' | translate) }}</ion-text>
        </div>
        <!-- <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div>
        <div class="ion-margin">
          <ion-img class="img center" src="./assets/wedding/img/accounts/setup/seating.svg"></ion-img>
        </div> -->
        <app-setting-seating [newMode]="true" [countryCode]="pageLocation?.locality?.country" [guestPerSeating]="guestPerSeating" [seatingType]="seatingType" [seatingTypeOther]="seatingTypeOther" [setInitial]="true" (setGuestPerSeating)="setGuestPerSeating($event)" (setSeatingType)="setSeatingType($event)" (setSeatingTypeOther)="setSeatingTypeOther($event)"></app-setting-seating>
      
        <ion-item class="website-input-item seating-count-item" lines="none" (click)="presentSeatingNewModal()" button detail>
          <ion-icon class="seating-count-icon" color="medium" src="./assets/wedding/icon/guest/seating.svg" slot="start"></ion-icon>
          <ion-label class="ion-no-margin">
            <ion-text class="font-14 ion-text-wrap" [innerHtml]="'SEATING.lbl.count' | translate: { seating: seatingTypeName }" color="dark"></ion-text>
            <!-- <ion-label class="font-10 ion-text-wrap" [innerHtml]="'SETTING.seating.list.msg' | translate" color="medium"></ion-label> -->
          </ion-label>
          <ion-note class="" color="dark" slot="end">{{ seatingList?.length ? seatingList.length : 0 }}</ion-note>
        </ion-item>
        <!-- <div>
          <ion-card class="seating-count-card ion-no-margin" color="light" button>
            
          </ion-card>
        </div> -->
      </div>
    </div>
  
    <div class="full-width-height" *ngIf="step === 'completed'">
      <div class="full-width-height ion-text-center center vertical-center ion-margin-bottom">
        
        <!-- <div class="ion-margin">
          <ng-container *ngIf="true then changeInfoBlock"></ng-container>
        </div> -->
        <div class="ion-margin-horizontal">
          <ion-text class="font-20 font-med" color="dark">{{ ('ACCOUNTS.setup.completed.title' | translate) }}</ion-text>
        </div>
        <div class="full-width ion-margin-horizontal">
          <app-website-success [ratio]="0.4"></app-website-success>
          <!-- <ion-img class="completed-img center" src="./assets/wedding/gif/account_setup/completed.gif"></ion-img> -->
        </div>
        <div class="ion-margin-horizontal">
          <ion-text class="font-14" color="dark" [innerHTML]="'ACCOUNTS.setup.completed.msg' | translate"></ion-text>
        </div>
      </div>
    </div>
  </ng-container>
</ion-content>
<ion-footer>
  <ion-toolbar class="ion-padding ion-text-center">
    <div class="content-div">
      <ion-button class="ion-margin-horizontal" expand="block" color="primary" fill="solid" type="button" (click)="submit()" [disabled]="checkDisabledSubmit()">
        <ng-container *ngIf="step === 'completed'">{{ 'BTN.get_start' | translate }}</ng-container>
        <ng-container *ngIf="step !== 'completed'">{{ 'BTN.continue' | translate }}</ng-container>
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>

<ng-template #changeInfoBlock>
  <!-- <div class="line-100">
    <ion-text class="font-10" [innerHTML]="'ACCOUNTS.msg.change_info_later' | translate">
    </ion-text>
  </div> -->
</ng-template>


<ng-template #imgBlock>
  <ng-container *ngIf="eventMode">
    <ion-img class="img center" src="./assets/wedding/img/accounts/love/event.webp"></ion-img>
  </ng-container>
  <ng-container *ngIf="!eventMode">
    <ng-container *ngIf="accountCoupleList?.length === 2">
      <ng-container *ngIf="accountCoupleList?.[0]?.coupleType === 'bride' && accountCoupleList?.[1]?.coupleType === 'bride'">
        <ion-img class="img center" src="./assets/wedding/img/accounts/love/bride_bride.svg"></ion-img>
      </ng-container>
      <ng-container *ngIf="accountCoupleList?.[0]?.coupleType === 'groom' && accountCoupleList?.[1]?.coupleType === 'groom'">
        <ion-img class="img center" src="./assets/wedding/img/accounts/love/groom_groom.svg"></ion-img>
      </ng-container>
      <ng-container *ngIf="accountCoupleList?.[0]?.coupleType === 'bride' && accountCoupleList?.[1]?.coupleType === 'groom'">
        <ion-img class="img center" src="./assets/wedding/img/accounts/love/bride_groom.svg"></ion-img>
      </ng-container>
      <ng-container *ngIf="accountCoupleList?.[0]?.coupleType === 'groom' && accountCoupleList?.[1]?.coupleType === 'bride'">
        <ion-img class="img center" src="./assets/wedding/img/accounts/love/groom_bride.svg"></ion-img>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>