/**
 * System default privilege for default role
 */
export const StdRolePrivilege = {
    groom: {
        account: [
            'view',
            'edit',
            'member',
            'setting'
        ],
        website: [
            'view',
            'designs',
            'contents',
            'invites'
        ],
        budget: [
            'view',
            'edit',
            'delete'
        ],
        expense: [
            'view',
            'edit',
            'delete'
        ],
        task: [
            'view',
            'edit',
            'delete'
        ],
        rsvp: [
            'view',
            'edit',
            'delete'
        ],
        guest: [
            'view',
            'new',
            'edit',
            'delete',
            'seating',
            'qrcode'
        ],
        checkin: [
            'view',
            'new',
            'edit',
            'checkin',
            'uncheck',
            'seating'
        ],
        qrcode: [
            'view',
            'edit',
            'delete'
        ],
        gift: [
            'view',
            'new',
            'edit',
            'delete'
        ],
        trash: [
            'view',
            'restore',
            'delete'
        ],
        blast: [
            'view',
            'send',
            'new',
            'edit',
        ]
    },
    bride: {
        account: [
            'view',
            'edit',
            'member',
            'setting'
        ],
        website: [
            'view',
            'designs',
            'contents',
            'invites'
        ],
        budget: [
            'view',
            'edit',
            'delete'
        ],
        expense: [
            'view',
            'edit',
            'delete'
        ],
        task: [
            'view',
            'edit',
            'delete'
        ],
        rsvp: [
            'view',
            'edit',
            'delete'
        ],
        guest: [
            'view',
            'new',
            'edit',
            'delete',
            'seating',
            'qrcode'
        ],
        checkin: [
            'view',
            'new',
            'edit',
            'checkin',
            'uncheck',
            'seating'
        ],
        qrcode: [
            'view',
            'edit',
            'delete'
        ],
        gift: [
            'view',
            'new',
            'edit',
            'delete'
        ],
        trash: [
            'view',
            'restore',
            'delete'
        ],
        blast: [
            'view',
            'send',
            'new',
            'edit',
        ]
    },
    admin: {
        account: [
            'view',
            'edit',
            'member',
            'setting'
        ],
        website: [
            'view',
            'designs',
            'contents',
            'invites'
        ],
        budget: [
            'view',
            'edit',
            'delete'
        ],
        expense: [
            'view',
            'edit',
            'delete'
        ],
        task: [
            'view',
            'edit',
            'delete'
        ],
        rsvp: [
            'view',
            'edit',
            'delete'
        ],
        guest: [
            'view',
            'new',
            'edit',
            'delete',
            'seating',
            'qrcode'
        ],
        checkin: [
            'view',
            'new',
            'edit',
            'checkin',
            'uncheck',
            'seating'
        ],
        qrcode: [
            'view',
            'edit',
            'delete'
        ],
        gift: [
            'view',
            'new',
            'edit',
            'delete'
        ],
        trash: [
            'view',
            'restore',
            'delete'
        ],
        blast: [
            'view',
            'send',
            'new',
            'edit',
        ]
    },
    wedding_planner: {
        budget: [
            'view',
            'edit',
            'delete'
        ],
        website: [
            'view',
            'designs',
            'contents',
            'invites'
        ],
        expense: [
            'view',
            'edit',
            'delete'
        ],
        task: [
            'view',
            'edit',
            'delete'
        ],
        rsvp: [
            'view',
            'edit',
            'delete'
        ],
        guest: [
            'view',
            'new',
            'edit',
            'delete',
            'seating',
            'qrcode'
        ],
        checkin: [
            'view',
            'new',
            'edit',
            'checkin',
            'uncheck',
            'seating'
        ],
        qrcode: [
            'view',
            'edit',
            'delete'
        ],
        gift: [
            'view',
            'new',
            'edit',
            'delete'
        ],
        trash: [
            'view',
            'restore',
            'delete'
        ],
        blast: [
            'view',
            'send',
            'new',
            'edit',
        ]
    },
    helper: {
        guest: [
            'view',
            'new',
            'edit',
            'delete',
            'seating',
            'qrcode'
        ],
        checkin: [
            'view',
            'new',
            'edit',
            'checkin',
            'uncheck',
            'seating'
        ],
        gift: [
            'view',
            'new',
        ],
    },
    usher: {
        checkin: [
            'view',
            'new',
            'edit',
            'checkin',
            'uncheck',
            'seating'
        ],
        gift: [
            'view',
            'new',
        ],
    },
    viewer: {
        account: [
            'view',
        ],
        budget: [
            'view',
        ],
        expense: [
            'view',
        ],
        task: [
            'view',
        ],
        rsvp: [
            'view',
        ],
        guest: [
            'view',
        ],
        checkin: [
            'view',
        ],
        qrcode: [
            'view',
        ],
        gift: [
            'view',
        ],
        trash: [
            'view',
        ],
        blast: [
            'view',
        ]
    },
};

export const StdEventRolePrivilege = {
    admin: {
        account: [
            'view',
            'edit',
            'member',
            'setting'
        ],
        website: [
            'view',
            'designs',
            'contents',
            'invites'
        ],
        budget: [
            'view',
            'edit',
            'delete'
        ],
        expense: [
            'view',
            'edit',
            'delete'
        ],
        task: [
            'view',
            'edit',
            'delete'
        ],
        rsvp: [
            'view',
            'edit',
            'delete'
        ],
        guest: [
            'view',
            'new',
            'edit',
            'delete',
            'seating',
            'qrcode'
        ],
        checkin: [
            'view',
            'new',
            'edit',
            'checkin',
            'uncheck',
            'seating'
        ],
        qrcode: [
            'view',
            'edit',
            'delete'
        ],
        gift: [
            'view',
            'new',
            'edit',
            'delete'
        ],
        trash: [
            'view',
            'restore',
            'delete'
        ],
        blast: [
            'view',
            'send',
            'new',
            'edit',
        ]
    },
    event_planner: {
        budget: [
            'view',
            'edit',
            'delete'
        ],
        website: [
            'view',
            'designs',
            'contents',
            'invites'
        ],
        expense: [
            'view',
            'edit',
            'delete'
        ],
        task: [
            'view',
            'edit',
            'delete'
        ],
        rsvp: [
            'view',
            'edit',
            'delete'
        ],
        guest: [
            'view',
            'new',
            'edit',
            'delete',
            'seating',
            'qrcode'
        ],
        checkin: [
            'view',
            'new',
            'edit',
            'checkin',
            'uncheck',
            'seating'
        ],
        qrcode: [
            'view',
            'edit',
            'delete'
        ],
        gift: [
            'view',
            'new',
            'edit',
            'delete'
        ],
        trash: [
            'view',
            'restore',
            'delete'
        ],
        blast: [
            'view',
            'send',
            'new',
            'edit',
        ]
    },
    coordinator: {
        guest: [
            'view',
            'new',
            'edit',
            'delete',
            'seating',
            'qrcode'
        ],
        checkin: [
            'view',
            'new',
            'edit',
            'checkin',
            'uncheck',
            'seating'
        ],
    },
    usher: {
        checkin: [
            'view',
            'new',
            'edit',
            'checkin',
            'uncheck',
            'seating'
        ],
    },
    viewer: {
        account: [
            'view',
        ],
        budget: [
            'view',
        ],
        expense: [
            'view',
        ],
        task: [
            'view',
        ],
        rsvp: [
            'view',
        ],
        guest: [
            'view',
        ],
        checkin: [
            'view',
        ],
        qrcode: [
            'view',
        ],
        gift: [
            'view',
        ],
        trash: [
            'view',
        ],
        blast: [
            'view',
        ]
    },
};
