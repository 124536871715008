<div *ngIf="attachmentType && websiteAttachment?.enable">
  <div class="ion-text-center ion-padding-horizontal">
    <ion-text class="font-20" [innerHTML]="websiteAttachment?.title" *ngIf="websiteAttachment?.title"></ion-text>
  </div>
  <div *ngIf="websiteAttachment?.attachmentUrl?.url" (click)="openFile(websiteAttachment)">
    <ng-container *ngIf="websiteAttachment?.mimeType.indexOf('image/') !== -1">
      <ion-img class="full-width" [src]="websiteAttachment?.attachmentUrl?.url"></ion-img>
    </ng-container>
    <ng-container *ngIf="websiteAttachment?.mimeType === 'application/pdf'">
      <ion-icon class="font-40 center" src="./assets/wedding/icon/general/document-outline.svg"></ion-icon>
    </ng-container>
  </div>
  <div class="ion-padding-horizontal" *ngIf="msg">
    <quill-view [content]="msg"></quill-view>
  </div>
</div>