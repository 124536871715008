import { WebsiteThemeBackground } from "../interfaces/website";

export const WebsiteThemeList: { [backgroundId: string]: WebsiteThemeBackground } = {
    '1': {
        backgroundId: '1',
        preview: "./assets/wedding/img/website/theme/background/1/background.svg",
        background: [
            { url: './assets/wedding/img/website/theme/background/1/top.svg', position: 'top' },
            { url: './assets/wedding/img/website/theme/background/1/bottom.svg', position: 'bottom' },
            { url: './assets/wedding/img/website/theme/background/1/background.svg', position: 'full' },
        ],
        backgroundSize: [
            'fixed',
            'cover',
            'scroll',
        ],
        order: 1,
        opacity: 0,
        colorId: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29' ],
    },
    '2': {
        backgroundId: '2',
        preview: "./assets/wedding/img/website/theme/background/2/background.svg",
        background: [
            { url: './assets/wedding/img/website/theme/background/2/top.svg', position: 'top' },
            { url: './assets/wedding/img/website/theme/background/2/bottom.svg', position: 'bottom' },
            { url: './assets/wedding/img/website/theme/background/2/background.svg', position: 'full' },
        ],
        backgroundSize: [
            'fixed',
            'cover',
            'scroll',
        ],
        order: 2,
        opacity: 0,
        colorId: [ '1', '2', '3', '4' ],
    },
    '3': {
        backgroundId: '3',
        preview: "./assets/wedding/img/website/theme/background/3/background.svg",
        background: [
            { url: './assets/wedding/img/website/theme/background/3/top.svg', position: 'top' },
            { url: './assets/wedding/img/website/theme/background/3/bottom.svg', position: 'bottom' },
            { url: './assets/wedding/img/website/theme/background/4/background.svg', position: 'full' },
        ],
        backgroundSize: [
            'fixed',
            'cover',
            'scroll',
        ],
        order: 3,
        opacity: 0,
        colorId: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29' ],
    },
    '4': {
        backgroundId: '4',
        preview: "./assets/wedding/img/website/theme/background/4/background.svg",
        background: [
            { url: './assets/wedding/img/website/theme/background/4/top.svg', position: 'top' },
            { url: './assets/wedding/img/website/theme/background/4/bottom.svg', position: 'bottom' },
            { url: './assets/wedding/img/website/theme/background/4/background.svg', position: 'full' },
        ],
        backgroundSize: [
            'fixed',
            'cover',
            'scroll',
        ],
        order: 4,
        opacity: 0,
        colorId: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29' ],
    },
    '5': {
        backgroundId: '5',
        preview: "./assets/wedding/img/website/theme/background/5/background.svg",
        background: [
            { url: './assets/wedding/img/website/theme/background/5/top.svg', position: 'top' },
            { url: './assets/wedding/img/website/theme/background/5/bottom.svg', position: 'bottom' },
            { url: './assets/wedding/img/website/theme/background/5/background.svg', position: 'full' },
        ],
        order: 5,
        opacity: 75,
        backgroundSize: [
            'fixed',
            'cover',
            'scroll',
        ],
        colorId: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29' ],
    },
    '6': {
        backgroundId: '6',
        preview: "./assets/wedding/img/website/theme/background/6/background.svg",
        background: [
            { url: './assets/wedding/img/website/theme/background/6/background.svg', position: 'full' },
        ],
        backgroundSize: [
            'cover',
            'scroll',
        ],
        order: 6,
        opacity: 75,
        colorId: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29' ],
    },
    '7': {
        backgroundId: '7',
        preview: "./assets/wedding/img/website/theme/background/7/background.svg",
        background: [
            { url: './assets/wedding/img/website/theme/background/7/background.svg', position: 'full' },
        ],
        backgroundSize: [
            'cover',
            'scroll',
        ],
        order: 7,
        opacity: 0,
        colorId: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29' ],
    },
    '0': {
        backgroundId: '0',
        preview: "",
        background: [
            { url: '', position: 'full' },
        ],
        backgroundSize: [
        ],
        order: 8,
        opacity: 0,
        colorId: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29' ],
    },
    'custom': {
        backgroundId: 'custom',
        preview: "",
        background: [
            { url: '', position: 'full' },
        ],
        backgroundSize: [
            'cover',
            'scroll',
        ],
        order: 9,
        opacity: 50,
        colorId: [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29' ],
    }
}