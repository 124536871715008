import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SettingFieldComponent } from 'src/app/components/setting/setting-field/setting-field.component';
import { SettingField } from 'src/app/interfaces/database';
import { UpdateBy } from 'src/app/interfaces/user';
import { WebsiteLink } from 'src/app/interfaces/website';
import { FunctionService } from 'src/app/services/general/function.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { CategoryService } from 'src/app/services/setting/category.service';
import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { WebsiteLinkService } from 'src/app/services/website/website-link.service';
import { WebsiteLinksService } from 'src/app/services/website/website-links.service';
import { SettingFieldType } from 'src/app/types/general';

@Component({
  selector: 'app-website-link-edit',
  templateUrl: './website-link-edit.component.html',
  styleUrls: ['./website-link-edit.component.scss'],
})
export class WebsiteLinkEditComponent  implements OnInit, OnDestroy {

  accountId: string;

  form: FormGroup;
  
  validationMsg: any;

  websiteLink: WebsiteLink;

  // private websiteLinks: WebsiteLink[];

  private websiteLinksSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private websiteLinksService: WebsiteLinksService,
    private websiteLinkService: WebsiteLinkService,
    private invitedByService: InvitedByService,
    private categoryService: CategoryService,
    private settingFieldService: SettingFieldService,
    private updateByService: UpdateByService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
    this.unwatchWebsiteLinks();
  }

  ionViewWillEnter() {
    this.watchWebsiteLinks();
    this.setupForm();
  }

  ionViewWillLeave() {
    this.unwatchWebsiteLinks();
  }

  watchWebsiteLinks() {
    if (!this.websiteLinksSubscription) {
      this.websiteLinksSubscription = this.websiteLinksService.observable.subscribe((websiteLinks: WebsiteLink[]) => {
        // this.setupWebsiteLinks(websiteLinks);
        // console.log(websiteLinks);
        if (this.websiteLink?.linkId && websiteLinks?.[this.websiteLink.linkId] && !this.functionService.isEqual(this.websiteLink, websiteLinks[this.websiteLink.linkId])) {
          this.websiteLink = this.functionService.cloneDeep(websiteLinks[this.websiteLink.linkId]);
          this.setupFormValue();
        }
      })
    }
  }

  async unwatchWebsiteLinks() {
    if (this.websiteLinksSubscription) {
      this.websiteLinksSubscription.unsubscribe();
      this.websiteLinksSubscription = null;
    }
  }

  // setupWebsiteLinks(websiteLinks: WebsiteLink[]) {
  //   this.websiteLinks = this.functionService.cloneDeep(websiteLinks.sort((a: WebsiteLink, b: WebsiteLink) => {
  //     return this.functionService.compare(a?.createBy?.time?.seconds, b?.createBy?.time?.seconds, true);
  //   }));
  // }

  setupForm() {
    this.form = this.formBuilder.group({
      name: new FormControl('', [ Validators.required ]),
      invitedByEnable: new FormControl(true),
      invitedBy: new FormControl('', [ Validators.required ]),
      categoryEnable: new FormControl(true),
      category: new FormControl('', [ Validators.required ]),
      group: new FormControl(true),
      maxGuest: new FormControl(10, [ Validators.required ]),
    });

    this.validationMsg = {
      name: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('WEBSITE.link.lbl.name') }) }],
      invitedBy: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('GUEST.lbl.invited_by') }) }],
      category: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('LBL.category') }) }],
      maxGuest: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('WEBSITE.link.lbl.max_guest') }) },
        { type: 'min', msg: this.translate.instant('VALIDATION.min_number', { number: 2 }) }
      ],
    };
    this.setupFormValue();
  }
  
  setupFormValue() {
    if (this.form) {
      if (!this.functionService.isUndefined(this.websiteLink?.name)) {
        this.form.controls.name.setValue(this.websiteLink.name);
      }

      if (!this.functionService.isUndefined(this.websiteLink?.invitedBy)) {
        this.form.controls.invitedBy.setValue(this.websiteLink.invitedBy);
      } else {
        this.form.controls.invitedBy.setValue([]);
      }

      if (!this.functionService.isUndefined(this.websiteLink?.category)) {
        this.form.controls.category.setValue(this.websiteLink.category);
      } else {
        this.form.controls.category.setValue([]);
      }

      if (!this.functionService.isUndefined(this.websiteLink?.maxGuest)) {
        if (this.websiteLink.maxGuest > 1) {
          this.form.controls.group.setValue(true);
          this.form.controls.maxGuest.setValue(this.websiteLink.maxGuest);
        } else {
          this.form.controls.group.setValue(false);
          this.form.controls.maxGuest.setValue(1);
        }
      } else {
        this.form.controls.group.setValue(false);
        this.form.controls.maxGuest.setValue(1);
      }

      if (this.websiteLink?.quickShare) {
        this.form.controls.invitedByEnable.setValue(false);
        this.form.controls.categoryEnable.setValue(false);
        this.form.controls.group.setValue(false);
      } else {

      }
  
      this.form.updateValueAndValidity();
    } else {
      setTimeout(() => {
        this.setupFormValue();
      }, 200);
    }
  }

  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  toggleInvitedBy() {
    if (this.form?.value?.invitedByEnable) {
      this.form.controls.invitedBy.setValue([]);
      this.form.controls.invitedBy.setValidators(Validators.required);
    } else {
      this.form.controls.invitedBy.setValue([this.invitedByService.reservedField]);
      this.form.controls.invitedBy.setValidators(Validators.nullValidator);
    }
    this.form.controls.invitedBy.updateValueAndValidity();
  }

  toggleCategory() {
    if (this.form?.value?.categoryEnable) {
      this.form.controls.category.setValue([]);
      this.form.controls.category.setValidators(Validators.required);
    } else {
      this.form.controls.category.setValue([this.categoryService.reservedField]);
      this.form.controls.category.setValidators(Validators.nullValidator);
    }
    this.form.controls.category.updateValueAndValidity();
  }

  toggleGroup() {
    if (this.form?.value?.group) {
      this.form.controls.maxGuest.setValue(2);
      this.form.controls.maxGuest.setValidators([ Validators.required, Validators.min(2) ]);
    } else {
      this.form.controls.maxGuest.setValue(1);
      this.form.controls.maxGuest.setValidators([ Validators.nullValidator ]);
    }
    this.form.controls.maxGuest.updateValueAndValidity();
  }
  
  async back() {
    const websiteLink = this.generateData();

    if (!this.functionService.isEqual(websiteLink, await this.websiteLinkService.getWebsiteLink(websiteLink.linkId))) {
      this.confirmDiscard();
    } else {
      this.dismissModal();
    }
  }

  async confirmDiscard() {
    const actionSheet = await this.actionSheetController.create({
      header: this.translate.instant('DISCARD.msg'),
      buttons: [{
        role: 'destructive',
        text: this.translate.instant('DISCARD.btn.save'),
        icon: 'checkmark-done',
        handler: () => {
          this.save(true);
        }
      }, {
        text: this.translate.instant('DISCARD.btn.discard'),
        icon: 'trash',
        handler: () => {
          this.dismissModal();
        }
      }, {
        text: this.translate.instant('BTN.cancel'),
        icon: 'close',
        role: 'cancel',
        handler: () => {
        }
      }]
    });
    actionSheet.present();
  }

  async dismissModal(websiteLink?: WebsiteLink) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ websiteLink }); }
    }
  }

  /**
   * Present setting field cmodal
   * @param settingFieldType Setting field type
   * @param selected Selected setting field
   */
  async presentSettingFieldModal(settingFieldType: SettingFieldType, selected: SettingField[]) {
    const modal = await this.modalController.create({
      component: SettingFieldComponent,
      componentProps: {
        settingFieldType,
        selected
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.selected) {
        if (settingFieldType === 'invited_by') {
          if (this.form.value.category !== result.data.selected) {
            this.form.controls.invitedBy.setValue(result.data.selected);
          }
        } else if (settingFieldType === 'category') {
          if (this.form.value.category !== result.data.selected) {
            this.form.controls.category.setValue(result.data.selected);
          }
        } else if (settingFieldType === 'dietary_req') {
          if (this.form.value.dietaryReq !== result.data.selected) {
            this.form.controls.dietaryReq.setValue(result.data.selected);
          }
        } else if (settingFieldType === 'special_req') {
          if (this.form.value.specialReq !== result.data.selected) {
            this.form.controls.specialReq.setValue(result.data.selected);
          }
        } else if (settingFieldType === 'session') {
          if (this.form.value.session !== result.data.selected) {
            this.form.controls.session.setValue(result.data.selected);
          }
        }
      }
    });
  }

  async promptRemove(ionSlide?: any) {
    if (this.websiteLink?.linkId && this.websiteLink?.linkId) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_field', {
          field: this.translate.instant('WEBSITE.content.title')
        })
      );
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.confirm) {
          this.websiteLink.enable = false;
          this.save(true, this.websiteLink);
        }
      });
    }
    if (ionSlide) { ionSlide.close(); }
  }

  addPax() {
    this.form.controls.maxGuest.setValue(this.form.value?.maxGuest + 1);
    this.form.controls.maxGuest.updateValueAndValidity();
  }

  minusPax() {
    if (this.form?.value?.maxGuest > 2) {
      this.form.controls.maxGuest.setValue(this.form.value.maxGuest - 1);
      this.form.controls.maxGuest.updateValueAndValidity();
    }
  }

  generateData() {
    // const updateBy: UpdateBy = this.updateByService.getUpdateBy();
    const websiteLink: WebsiteLink = {
      linkId: this.websiteLink?.linkId ? this.websiteLink.linkId : '',
      accountId: this.websiteLink?.accountId ? this.websiteLink.accountId : this.websiteLinksService.accountId,
      name: this.form?.value?.name,
      invitedBy: this.form?.value?.invitedBy,
      category: this.form?.value?.category,
      maxGuest: this.form?.value?.maxGuest,
      link: {
        short: this.websiteLink?.link?.short ? this.websiteLink.link.short : '',
        url: this.websiteLink?.link?.url ? this.websiteLink.link.url : '',
      },
      enable: this.websiteLink?.enable ? true : false,
      quickShare: this.websiteLink?.quickShare ? true : false,
      preview: false,
      createBy: this.websiteLink?.createBy,
      updateBy: this.websiteLink?.updateBy,
    };

    if (!websiteLink?.link?.url && websiteLink?.linkId) {
      websiteLink.link.url = this.websiteLinkService.generateUrl(websiteLink.linkId);
    }
    return websiteLink;
  }

  async save(dismiss?: boolean, websiteLink?: WebsiteLink) {
    await this.popupService.presentLoading();
    if (!websiteLink) {
      websiteLink = this.generateData();
    }
    if (!this.functionService.isEqual(websiteLink, await this.websiteLinkService.getWebsiteLink(websiteLink.linkId))) {
      await this.websiteLinkService.saveWebsiteLink(websiteLink);
    }
    await this.popupService.dismissLoading();
    await this.popupService.saveSuccessToast();
    if (dismiss) {
      await this.dismissModal();
    }
  }

}
