import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { AccountInfo } from 'src/app/interfaces/account';

import { WebsiteAttachment, WebsiteDetail } from 'src/app/interfaces/website';
import { FileService } from 'src/app/services/general/file.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { GpsService } from 'src/app/services/general/gps.service';
import { VisitorSettingService } from 'src/app/services/visitor/visitor-setting.service';
import { GpsType } from 'src/app/types/visitor';


@Component({
  selector: 'app-website-venue',
  templateUrl: './website-venue.component.html',
  styleUrls: ['./website-venue.component.scss'],
})
export class WebsiteVenueComponent  implements OnInit, OnDestroy {

  /**
   * Watch screen resize change
   */
  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.setupMaxWidth();
  }
  /**
   * Watch screen orientation change
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event: any) {
    this.setupMaxWidth();
  }

  @Input() updateTheme: boolean;
  
  @Input() previewMode: boolean;
  @Input() pageMode: boolean;

  @Input() set setWebsiteDetail(websiteDetail: WebsiteDetail) {
    this.websiteDetail = websiteDetail;
    this.setup();
  };

  @Input() set setAccountInfo(accountInfo: AccountInfo) {
    this.accountInfo = accountInfo;
    this.setup();
  }

  @Input() mediaHeight: number;

  gpsMaxWidth: number;

  websiteDetail: WebsiteDetail;
  accountInfo: AccountInfo;

  venue: string;
  hall: string;
  address: string;
  remark: string;

  gps = {
    googleMap: false,
    waze: false,
    uber: false,
    grab: false,
    appleMap: false,
  };

  constructor(
    private visitorSettingService: VisitorSettingService,
    private gpsService: GpsService,
    private fileService: FileService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {
    this.setupMaxWidth();
  }

  ngOnDestroy(): void {
  }

  setup() {
    this.venue = this.getVenueName();
    this.address = this.getVenueAddress();
    this.hall = this.getContent(this.websiteDetail?.venue?.hall);
    this.remark = this.getContent(this.websiteDetail?.venue?.remark);
    this.gps.googleMap = this.checkGps('googleMap');
    this.gps.waze = this.checkGps('waze');
    this.gps.uber = this.checkGps('uber');
    this.gps.grab = this.checkGps('grab');
    this.gps.appleMap = this.checkGps('appleMap');
  }

  getContent(msg: string) {
    if (msg) {
      if (!this.functionService.sanitizeHtml(msg).trim()) {
        return '';
      }
    }
    return msg;
  }

  getVenueName() {
    if (this.websiteDetail?.venue?.name) {
      return this.getContent(this.websiteDetail.venue.name);
    } else if (this.functionService.isUndefined(this.websiteDetail?.venue?.name) && this.accountInfo?.location?.name) {
      return this.accountInfo.location.name;
    }
    return '';
  }

  getVenueAddress() {
    if (this.websiteDetail?.venue?.address) {
      return this.getContent(this.websiteDetail.venue.address);
    } else if (this.functionService.isUndefined(this.websiteDetail?.venue?.address) && this.accountInfo?.location?.address) {
      return this.accountInfo.location.address;
    }
    return '';
  }

  setupMaxWidth() {
    this.gpsMaxWidth = this.calculateMaxWidth();
  }

  calculateMaxWidth() {
    const width = 70;
    let result = 0;
    if (this.checkGps('googleMap')) {
      result += width;
    }
    if (this.checkGps('waze')) {
      result += width;
    }
    if (this.checkGps('uber')) {
      result += width;
    }
    if (this.checkGps('grab')) {
      result += width;
    }
    if (this.checkGps('appleMap')) {
      result += width;
    }
    return result;
  }

  checkGps(type: GpsType): boolean {
    if (type && this.accountInfo?.location?.coordinate) {
      let country = '';
      if (this.accountInfo?.location?.locality?.country) {
        country = this.accountInfo?.location.locality.country;
      } else if (this.accountInfo?.location?.locality?.country) {
        country = this.accountInfo.location.locality.country
      }

      if (country) {
        country = country.toUpperCase();
        if (type === 'googleMap') {
          if ((this.websiteDetail?.venue?.gps?.googleMap || this.functionService.isUndefined(this.websiteDetail?.venue?.gps?.googleMap)) && this.visitorSettingService.checkGoogleMapCountry(country)) {
            return true;
          }
        } else if (type === 'waze') {
          if ((this.websiteDetail?.venue?.gps?.waze || this.functionService.isUndefined(this.websiteDetail?.venue?.gps?.waze)) && this.visitorSettingService.checkWazeCountry(country)) {
            return true;
          }
        } else if (type === 'uber') {
          if ((this.websiteDetail?.venue?.gps?.uber || this.functionService.isUndefined(this.websiteDetail?.venue?.gps?.uber)) && this.visitorSettingService.checkUberCountry(country)) {
            return true;
          }
        } else if (type === 'grab') {
          if ((this.websiteDetail?.venue?.gps?.grab || this.functionService.isUndefined(this.websiteDetail?.venue?.gps?.grab)) && this.visitorSettingService.checkGrabCountry(country)) {
            return  true;
          }
        } else if (type === 'appleMap') {
          if ((this.websiteDetail?.venue?.gps?.appleMap || this.functionService.isUndefined(this.websiteDetail?.venue?.gps?.appleMap))) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * Open google navigation
   */
  openGoogleNav() {
    this.gpsService.openGoogleNav(this.accountInfo?.location);
  }

  /**
   * Open waze navigation
   */
  openWazeNav() {
    this.gpsService.openWazeNav(this.accountInfo?.location);
  }

  /**
   * Open uber navigation
   */
  openUberNav() {
    this.gpsService.openUberNav(this.accountInfo?.location);
  }

  openGrab() {
    this.gpsService.openGrab(this.accountInfo?.location);
  }

  openAppleNav() {
    this.gpsService.openAppleNav(this.accountInfo?.location);
  }

  openFile(attachment: WebsiteAttachment) {
    this.fileService.openFile(attachment?.attachmentUrl?.url, attachment.mimeType);
  }

}
