import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { WebsiteTheme, WebsiteThemeColor, WebsiteThemeCover } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';

import { WebsiteFontService } from './website-font.service';
import { WebsiteCoverSample } from 'src/app/commons/website';
import { AccountInfoService } from '../account/account-info.service';
import { WebsiteThemeColorList } from 'src/app/commons/websiteThemeColor';
import { WebsiteThemeList } from 'src/app/commons/websiteTheme';
import { WebsiteBackgroundSize } from 'src/app/types/website';
import { UpdateByService } from '../user/update-by.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteThemeService implements OnInit, OnDestroy {

  accountId: string;

  websiteTheme: WebsiteTheme;

  observable: any;

  constructor(
    private afs: AngularFirestore,
    private accountInfoService: AccountInfoService,
    private websiteFontService: WebsiteFontService,
    private functionService: FunctionService,
    private updateByService: UpdateByService,
  ) {
    this.observable = new BehaviorSubject<WebsiteTheme>(this.websiteTheme);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  setup(websiteTheme: WebsiteTheme | any) {
    if (!this.functionService.isEqual(this.websiteTheme, websiteTheme)) {
      if (!websiteTheme?.backgroundId) {
        websiteTheme.backgroundId = websiteTheme?.themeId ? websiteTheme.themeId : '1';
      }
      if (!websiteTheme?.cover) {
        websiteTheme.cover = this.getWebsiteCover();
      }
      if (!websiteTheme?.opacity) {
        websiteTheme.opacity = 0;
      }
      if (!websiteTheme?.backgroundSize) {
        const { backgroundSizeList } = this.getWebsiteBackgroundSizeList(websiteTheme.backgroundId, '', websiteTheme.opacity);
        if (backgroundSizeList?.length) {
          websiteTheme.backgroundSize = backgroundSizeList[0];
        }
      }
      if (!websiteTheme?.colorId) {
        websiteTheme.colorId = '1';
      }
      if (!websiteTheme?.font) {
        websiteTheme.font = this.websiteFontService.getDefaultFont(this.accountInfoService.accountInfo?.locale);
      }
      this.websiteTheme = websiteTheme;
      this.observable.next(this.websiteTheme);
    }
  }

  getWebsiteCover(): WebsiteThemeCover {
    if (this.websiteTheme?.cover) {
      return this.websiteTheme.cover;
    } else {
      return this.getStdWebsiteCover();
    }
  }

  getStdWebsiteCover(): WebsiteThemeCover {
    return {
      media: WebsiteCoverSample[0],
      text: {
        opacity: 50,
        position: 'bottom',
      },
    };
  }

  getWebsiteTheme(): WebsiteTheme {
    if (this.websiteTheme) {
      return { ...this.websiteTheme };
    } else {
      return {
        cover: this.getStdWebsiteCover(),
        backgroundSize: 'fixed',
        opacity: 0,
        backgroundId: '1',
        colorId: '1',
        font: this.websiteFontService.getDefaultFont(this.accountInfoService.accountInfo?.locale),
        // font: this.websiteFontService.getDefaultFont(this.websiteTimeService.getWebsiteTime().locale),
      };
    }
  }

  getWebsiteBackgroundSizeList(backgroundId: string, backgroundSize: WebsiteBackgroundSize, opacity: number) {
    const websiteThemeBackground = WebsiteThemeList[backgroundId];
    const backgroundSizeList = websiteThemeBackground?.backgroundSize;
    if (backgroundSizeList?.length) {
      if (!backgroundSize || backgroundSizeList?.indexOf(backgroundSize) === -1) {
        backgroundSize = backgroundSizeList[0];
      }
    } else {
      backgroundSize = '';
    }
    
    opacity = websiteThemeBackground?.opacity ? websiteThemeBackground.opacity : opacity;
    
    return { backgroundSizeList, backgroundSize, opacity };
  }

  getWebsiteColor(colorId): WebsiteThemeColor {
    return WebsiteThemeColorList?.[colorId];
  }

  async save(theme: WebsiteTheme) {
    if (this.accountId && !this.functionService.isEqual(theme, this.websiteTheme)) {
      theme.updateBy = this.updateByService.updateBy;
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ theme }, { merge: true });
    }
  }

  async saveWebsiteCover(cover: any) {
    if (this.accountId && !this.functionService.isEqual(cover, this.websiteTheme?.cover)) {
      cover.updateBy = this.updateByService.updateBy;
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ theme: { cover } }, { merge: true });
    }
  }

  calculatePreviewWidth(containerWidth: number): number {
    // Define constants
    const minItemSize = 140;
    const maxItemSize = 400;
    const padding = 10;
    const outerPadding = 5;
    const minColumns = 2;

    // Calculate the effective width of the container after removing outer padding
    const effectiveWidth = containerWidth - (2 * outerPadding);
    let bestSize = minItemSize;

    // Iterate over possible numbers of columns, starting from minColumns
    for (let columns = minColumns; columns <= Math.floor(effectiveWidth / minItemSize); columns++) {
      // Calculate the potential item size for the current number of columns
      let potentialSize = (effectiveWidth / columns) - (2 * padding);

      // Check if the potential size is within the allowed range
      if (potentialSize >= minItemSize && potentialSize <= maxItemSize) {
        bestSize = Math.max(bestSize, Math.floor(potentialSize));
      } else if (potentialSize < minItemSize) {
        // If the potential size falls below the minimum item size, stop the loop
        break;
      }
    }

    return bestSize;
  }
}
