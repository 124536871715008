<ng-container *ngIf="design === 1">
  <ng-container *ngIf="formatStartDate">
    <ng-container *ngIf="!formatEndDate || formatEndDate === formatStartDate else diffDateBlock">
      <div class="ion-text-center" *ngIf="formatStartDate">
        <ion-text class="line-130" [style.fontSize]="fontSize1" [innerHTML]="formatStartDate"></ion-text>
      </div>
      
      <div>
        <ion-grid class="full-width ion-no-padding">
          <ion-row>
            <ion-col [class]="endTime ? 'ion-text-right' : 'ion-text-center'" *ngIf="formatStartTime">
              <ion-text class="line-130" [style.fontSize]="fontSize2">{{ formatStartTime }}</ion-text>
            </ion-col>
            <ion-col class="ion-text-center" [size]="1" *ngIf="startTime && endTime">
              <ion-text class="line-130" [style.fontSize]="fontSize2">&nbsp; - &nbsp;</ion-text>
            </ion-col>
            <ion-col [class]="startTime ? 'ion-text-left' : 'ion-text-center'" *ngIf="formatEndTime">
              <ion-text class="line-130" [style.fontSize]="fontSize2">{{ formatEndTime }}</ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ng-container>
    <ng-template #diffDateBlock>
      <div class="ion-text-center" *ngIf="formatStartDate">
        <ion-text class="line-130" [style.fontSize]="fontSize1" [innerHTML]="formatStartDate"></ion-text>
      </div>
      <div class="ion-text-center" *ngIf="formatStartTime">
        <ion-text class="line-130" [style.fontSize]="fontSize2" [innerHTML]="formatStartTime"></ion-text>
      </div>
      <div class="ion-text-center" *ngIf="formatEndTime">
        <ion-text class="line-130" [style.fontSize]="fontSize2" [innerHTML]="'-'"></ion-text>
      </div>
      <div class="ion-text-center" *ngIf="formatEndDate">
        <ion-text class="line-130" [style.fontSize]="fontSize1" [innerHTML]="formatEndDate"></ion-text>
      </div>
      <div class="ion-text-center" *ngIf="formatEndTime">
        <ion-text class="line-130" [style.fontSize]="fontSize2" [innerHTML]="formatEndTime"></ion-text>
      </div>
    </ng-template>
  </ng-container>
  
</ng-container>

<ng-container *ngIf="design === 2">
  <div class="ion-text-center">
    <ng-container *ngIf="formatStartDate">
      <ng-container *ngIf="!formatEndDate || formatEndDate === formatStartDate else diffDateBlock">
        <ion-text class="line-130" [style.fontSize]="fontSize1">{{ formatStartDate }}</ion-text>
        <ion-text class="line-130" [style.fontSize]="fontSize1" *ngIf="startTime">&nbsp;{{ formatStartTime }}</ion-text>
        <ion-text class="line-130" [style.fontSize]="fontSize1" *ngIf="startTime && endTime">&nbsp; - &nbsp;</ion-text>
        <ion-text class="line-130" [style.fontSize]="fontSize1" *ngIf="formatEndDate && formatStartDate !== formatEndDate">{{ formatEndDate }}&nbsp;</ion-text>
        <ion-text class="line-130" [style.fontSize]="fontSize1" *ngIf="endTime">{{ formatEndTime }}</ion-text>
      </ng-container>
      <ng-template #diffDateBlock>
        <ion-text class="line-130" [style.fontSize]="fontSize1">{{ formatStartDate }}</ion-text>
        <ion-text class="line-130" [style.fontSize]="fontSize1" *ngIf="startTime">&nbsp;{{ formatStartTime }}</ion-text>
        <div>
          <ion-text class="line-130" [style.fontSize]="fontSize1" *ngIf="startTime && endTime">&nbsp; - &nbsp;</ion-text>
        </div>
        <ion-text class="line-130" [style.fontSize]="fontSize1" *ngIf="formatEndDate">{{ formatEndDate }}&nbsp;</ion-text>
        <ion-text class="line-130" [style.fontSize]="fontSize1" *ngIf="endTime">{{ formatEndTime }}</ion-text>
      </ng-template>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="design === 3">
  <ion-grid class="date2-grid ion-no-padding ion-text-center">
    <ion-row class="ion-no-padding ion-align-items-center">
      <ion-col>
        <ion-text [style.fontSize]="fontSize2">{{ format(startTime * 1000, 'MMMM', timezone?.name, locale) }}</ion-text>
      </ion-col>
    </ion-row>
    <ion-row class="ion-no-padding ion-align-items-center">
      <ion-col class="border-top border-bottom" size="4" [style.border-color]="websiteThemeColor?.font ? websiteThemeColor.font : ''">
        <ion-text [style.fontSize]="fontSize3">{{ format(startTime * 1000, 'cccc', timezone?.name, locale) }}</ion-text>
      </ion-col>
      <ion-col size="4" style="padding: 0 6px;">
        <ion-text class="font-bold" [style.fontSize]="fontSize1">{{ format(startTime * 1000, 'dd', timezone?.name, locale) }}</ion-text>
      </ion-col>
      <ion-col class="border-top border-bottom" [style.border-color]="websiteThemeColor?.font ? websiteThemeColor.font : ''" size="4">
        <div>
          <ion-text [style.fontSize]="fontSize3">{{ format(startTime * 1000, 'h:mma', timezone?.name, locale) | uppercase }}</ion-text>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="ion-no-padding ion-align-items-center">
      <ion-col>
        <ion-text [style.fontSize]="fontSize2">{{ format(startTime * 1000, 'yyyy', timezone?.name, locale) }}</ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-container>

<div class="ion-text-center ion-margin-vertical" *ngIf="getSaveCalendar()">
  <!-- [style.color]="websiteThemeColor?.button?.font" -->
  <ion-icon slot="icon-only" [style.font-size]="iconSize" src="./assets/wedding/icon/general/calendar-outline.svg" (click)="openCalendar('ical')"></ion-icon>
  <!-- <ion-button class="ion-padding" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" [style.--btnFont]="websiteThemeColor?.button?.font" [style.--btnBackground]="websiteThemeColor?.button?.color" size="small">
    {{ 'WEBSITE.time.save_calendar.title' | translate }}
  </ion-button> -->
</div>




<!-- <ng-template #dateGrid>
  <ng-container>
    <ng-container *ngIf="getEventTimestamp() as timestamp">
      <ion-grid class="ion-no-padding" fixed>
        <ion-row class="font-10 ion-no-padding">
          <ion-col size="4">
            {{ lbl.date }}
          </ion-col>
          <ion-col size="4">
            {{ lbl.month }}
          </ion-col>
          <ion-col size="4">
            {{ lbl.year }}
          </ion-col>
        </ion-row>
        <ion-row class="font-40 font-bold ion-no-padding">
          <ion-col>{{ formatDate(timestamp, 'dd') }}</ion-col>
          <ion-col class="ion-text-uppercase">{{ getMonth(timestamp) }}</ion-col>
          <ion-col>{{ formatDate(timestamp, 'yy') }}</ion-col>
        </ion-row>
      </ion-grid>
      <div class="font-14 font-bold">
        {{ formatDate(timestamp, 'cccc') }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #date1>
  <div *ngIf="getDate() as date">
    <ion-text class="font-16 font-bold line-130" [innerHTML]="date"></ion-text>
  </div>
</ng-template>

<ng-template #date2>
  <ng-container *ngIf="getEventTimestamp() as timestamp">
    <ion-grid class="date2-grid ion-no-padding ion-text-center">
      <ion-row class="ion-no-padding ion-align-items-center">
        <ion-col class="font-16">{{ formatDate(timestamp, 'MMMM') }}</ion-col>
      </ion-row>
      <ion-row class="ion-no-padding ion-align-items-center">
        <ion-col class="font-14 border-top border-bottom" size="4">{{ formatDate(timestamp, 'cccc') }}</ion-col>
        <ion-col class="font-40 font-bold" size="4" style="padding: 0 6px;">{{ formatDate(timestamp, 'dd') }}</ion-col>
        <ion-col class="border-top border-bottom" size="4">
          <div class="font-14 ">
            <ion-text class="font-14">{{ formatDate(timestamp, 'h:mma') | uppercase }}</ion-text>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-no-padding ion-align-items-center">
        <ion-col class="font-16">{{ formatDate(timestamp, 'yyyy') }}</ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>
</ng-template>

<ng-template #date3>
  <ng-container *ngIf="getEventTimestamp() as timestamp">
    <ion-grid class="date3-grid ion-no-padding ion-text-center">
      <ion-row class="ion-no-padding ion-align-items-center">
        <ion-col class="font-14 letter-spacing-4">&nbsp;&nbsp;{{ formatDate(timestamp, 'ccc') | uppercase }}&nbsp;&nbsp;</ion-col>
        <ion-col>
          <div class="round-div vertical-center">
            <div><ion-text class="font-14 letter-spacing-4" size="3">{{ formatDate(timestamp, 'MMM') | uppercase }}</ion-text></div>
            <div>
              <ion-text class="font-40 letter-spacing-4 font-bold">{{ formatDate(timestamp, 'dd') }}</ion-text>
            </div>
            <div><ion-text class="font-14 letter-spacing-4" size="3">{{ formatDate(timestamp, 'yyyy') }}</ion-text></div>
          </div>
        </ion-col>
        <ion-col>
          <div class="font-14 letter-spacing-1">
            <ion-text class="font-14 letter-spacing-2">{{ formatDate(timestamp, 'h:mm a') | uppercase }}</ion-text>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>
</ng-template> -->