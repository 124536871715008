<div class="ion-margin-vertical">
  <div class="ion-margin-vertical" *ngIf="websiteDetail?.venue?.title || websiteDetail?.venue?.msg">
    <quill-view [content]="websiteDetail.venue.title" *ngIf="getContent(websiteDetail?.venue?.title)"></quill-view>
    <quill-view [content]="websiteDetail.venue.msg" *ngIf="getContent(websiteDetail?.venue?.msg)"></quill-view>
  
    <ng-container *ngIf="websiteDetail?.venue?.media?.url">
      <ng-container *ngIf="websiteDetail?.venue?.media.type === 'youtube'">
        <app-youtube [websiteMode]="true" [setCardHeight]="mediaHeight" [setCalculateSize]="updateTheme" [setSrc]="websiteDetail?.venue?.media.url"></app-youtube>
      </ng-container>
      <ng-container *ngIf="websiteDetail?.venue?.media.type === 'base64' || websiteDetail?.venue?.media.type === 'firebase'">
        <ion-img class="full-width center" [src]="websiteDetail?.venue?.media.url"></ion-img>
      </ng-container>
    </ng-container>
  </div>
  <div class="ion-margin-vertical">
    <div class="margin-5" *ngIf="venue">
      <ion-text class="line-130">
        <quill-view [content]="venue"></quill-view>
      </ion-text>
    </div>

    <div class="margin-5" *ngIf="hall">
      <ion-text class="line-130">
        <quill-view [content]="hall"></quill-view>
      </ion-text>
    </div>

    <div class="margin-5" *ngIf="address">
      <ion-text class="">
        <quill-view [content]="address"></quill-view>
      </ion-text>
    </div>

    <div class="margin-5" *ngIf="remark">
      <ion-text class="line-130">
        <quill-view [content]="remark"></quill-view>
      </ion-text>
    </div>
  </div>

  <div class="ion-text-center ion-margin-vertical" *ngIf="accountInfo?.location?.coordinate">
    <ion-grid class="gps-grid full-width" [style.maxWidth.px]="gpsMaxWidth" fixed>
      <ion-row class="ion-align-items-center ion-justify-content-center">
        <ion-col *ngIf="gps?.googleMap">
          <ion-img class="gps-img googleMap-icon" src="./assets/wedding/img/visitor/gps/google.png" (click)="openGoogleNav()"></ion-img>
        </ion-col>
        <ion-col *ngIf="gps?.waze">
          <ion-icon class="icon-large waze-icon" src="./assets/wedding/img/visitor/gps/waze.svg" (click)="openWazeNav()"></ion-icon>
        </ion-col>
        <ion-col *ngIf="gps?.uber">
          <ion-img class="gps-img" src="./assets/wedding/img/visitor/gps/uber.png" (click)="openUberNav()"></ion-img>
        </ion-col>
        <ion-col *ngIf="gps?.grab">
          <ion-icon class="icon-large" src="./assets/wedding/img/visitor/gps/grab.svg" (click)="openGrab()"></ion-icon>
        </ion-col>
        <ion-col *ngIf="gps?.appleMap">
          <ion-img class="gps-img" src="./assets/wedding/img/visitor/gps/apple.png" (click)="openAppleNav()"></ion-img>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!-- <div class="ion-padding" *ngIf="websiteDetail?.venue?.attachment?.dressCode?.enable">
    <div class="ion-text-center">
      <ion-text class="font-20" [innerHTML]="websiteDetail?.venue?.attachment?.dressCode?.title" *ngIf="websiteDetail?.venue?.attachment?.dressCode?.title"></ion-text>
    </div>
    <div *ngIf="getContent(websiteDetail?.venue?.attachment?.dressCode?.msg)">
      <quill-view [content]="websiteDetail?.venue?.attachment?.dressCode?.msg"></quill-view>
    </div>
    <div *ngIf="websiteDetail?.venue?.attachment?.dressCode?.attachmentUrl?.url" (click)="openFile(websiteDetail.venue.attachment.dressCode)">
      <ng-container *ngIf="websiteDetail?.venue?.attachment?.dressCode?.mimeType.indexOf('image/') !== -1">
        <ion-img class="full-width" [src]="websiteDetail?.venue?.attachment?.dressCode?.attachmentUrl?.url"></ion-img>
      </ng-container>
      <ng-container *ngIf="websiteDetail?.venue?.attachment?.dressCode?.mimeType === 'application/pdf'">
        <ion-icon class="font-40 center" src="./assets/wedding/icon/general/document-outline.svg"></ion-icon>
      </ng-container>
    </div>
  </div> -->

  <app-website-attachment [setWebsiteAttachment]="websiteDetail?.venue?.attachment?.parking" [attachmentType]="'parking'"></app-website-attachment>
  <app-website-attachment [setWebsiteAttachment]="websiteDetail?.venue?.attachment?.menu" [attachmentType]="'menu'"></app-website-attachment>
  <app-website-attachment [setWebsiteAttachment]="websiteDetail?.venue?.attachment?.floorplan" [attachmentType]="'floorplan'"></app-website-attachment>

  <!-- <div class="ion-padding" *ngIf="websiteDetail?.venue?.attachment?.parking?.enable">
    <div class="ion-text-center">
      <ion-text class="font-20" [innerHTML]="websiteDetail?.venue?.attachment?.parking?.title" *ngIf="websiteDetail?.venue?.attachment?.parking?.title"></ion-text>
    </div>
    <div *ngIf="getContent(websiteDetail?.venue?.attachment?.parking?.msg)">
      <quill-view [content]="websiteDetail?.venue?.attachment?.parking?.msg"></quill-view>
    </div>
    <div *ngIf="websiteDetail?.venue?.attachment?.parking?.attachmentUrl?.url" (click)="openFile(websiteDetail.venue.attachment.parking)">
      <ng-container *ngIf="websiteDetail?.venue?.attachment?.parking?.mimeType.indexOf('image/') !== -1">
        <ion-img class="full-width" [src]="websiteDetail?.venue?.attachment?.parking?.attachmentUrl?.url"></ion-img>
      </ng-container>
      <ng-container *ngIf="websiteDetail?.venue?.attachment?.parking?.mimeType === 'application/pdf'">
        <ion-icon class="font-40 center" src="./assets/wedding/icon/general/document-outline.svg"></ion-icon>
      </ng-container>
    </div>
  </div> -->

  <!-- <div class="ion-padding" *ngIf="websiteDetail?.venue?.attachment?.menu?.enable">
    <div class="ion-text-center">
      <ion-text class="font-20" [innerHTML]="websiteDetail?.venue?.attachment?.menu?.title" *ngIf="websiteDetail?.venue?.attachment?.menu?.title"></ion-text>
    </div>
    <div *ngIf="getContent(websiteDetail?.venue?.attachment?.menu?.msg)">
      <quill-view [content]="websiteDetail?.venue?.attachment?.menu?.msg"></quill-view>
    </div>
    <div *ngIf="websiteDetail?.venue?.attachment?.menu?.attachmentUrl?.url" (click)="openFile(websiteDetail.venue.attachment.menu)">
      <ng-container *ngIf="websiteDetail?.venue?.attachment?.menu?.mimeType.indexOf('image/') !== -1">
        <ion-img class="full-width" [src]="websiteDetail?.venue?.attachment?.menu?.attachmentUrl?.url"></ion-img>
      </ng-container>
      <ng-container *ngIf="websiteDetail?.venue?.attachment?.menu?.mimeType === 'application/pdf'">
        <ion-icon class="font-40 center" src="./assets/wedding/icon/general/document-outline.svg"></ion-icon>
      </ng-container>
    </div>
  </div> -->

  <!-- <div class="ion-padding" *ngIf="websiteDetail?.venue?.attachment?.floorplan?.enable">
    <div class="ion-text-center">
      <ion-text class="font-20" [innerHTML]="websiteDetail?.venue?.attachment?.floorplan?.title" *ngIf="websiteDetail?.venue?.attachment?.floorplan?.title"></ion-text>
    </div>
    <div *ngIf="getContent(websiteDetail?.venue?.attachment?.floorplan?.msg)">
      <quill-view [content]="websiteDetail?.venue?.attachment?.floorplan?.msg"></quill-view>
    </div>
    <div *ngIf="websiteDetail?.venue?.attachment?.floorplan?.attachmentUrl?.url" (click)="openFile(websiteDetail.venue.attachment.floorplan)">
      <ng-container *ngIf="websiteDetail?.venue?.attachment?.floorplan?.mimeType.indexOf('image/') !== -1">
        <ion-img class="full-width" [src]="websiteDetail?.venue?.attachment?.floorplan?.attachmentUrl?.url"></ion-img>
      </ng-container>
      <ng-container *ngIf="websiteDetail?.venue?.attachment?.floorplan?.mimeType === 'application/pdf'">
        <ion-icon class="font-40 center" src="./assets/wedding/icon/general/document-outline.svg"></ion-icon>
      </ng-container>
    </div>
  </div> -->

    <!-- <div class="ion-padding" *ngIf="websiteDetail?.venue?.attachment?.itinerary?.enable">
    <div class="ion-text-center">
      <ion-text class="font-20" [innerHTML]="websiteDetail?.venue?.attachment?.itinerary?.title" *ngIf="websiteDetail?.venue?.attachment?.itinerary?.title"></ion-text>
    </div>
    <div *ngIf="websiteDetail?.venue?.attachment?.itinerary?.msg">
      <quill-view [content]="websiteDetail?.venue?.attachment?.itinerary?.msg"></quill-view>
    </div>
    <div *ngIf="websiteDetail?.venue?.attachment?.itinerary?.attachmentUrl?.url" (click)="openFile(websiteDetail.venue.attachment.itinerary)">
      <ng-container *ngIf="websiteDetail?.venue?.attachment?.itinerary?.mimeType.indexOf('image/') !== -1">
        <ion-img class="full-width" [src]="websiteDetail?.venue?.attachment?.itinerary?.attachmentUrl?.url"></ion-img>
      </ng-container>
      <ng-container *ngIf="websiteDetail?.venue?.attachment?.itinerary?.mimeType === 'application/pdf'">
        <ion-icon class="font-40 center" src="./assets/wedding/icon/general/document-outline.svg"></ion-icon>
      </ng-container>
    </div>
  </div> -->
</div>