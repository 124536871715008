import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { WebsiteVenue } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';
import { VisitorSettingService } from '../visitor/visitor-setting.service';
import { AccountInfoService } from '../account/account-info.service';
import { WebsiteAttachmentService } from './website-attachment.service';
import { AccountEventModeService } from '../account/account-event-mode.service';
import { UpdateByService } from '../user/update-by.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteVenueService implements OnInit, OnDestroy {

  accountId: string;

  websiteVenue: WebsiteVenue;

  observable: any;

  constructor(
    private afs: AngularFirestore,
    private translate: TranslateService,
    private accountInfoService: AccountInfoService,
    private accountEventModeService: AccountEventModeService,
    private websiteAttachmentService: WebsiteAttachmentService,
    // private websiteContenstService: WebsiteContentsService,
    private visitorSettingService: VisitorSettingService,
    private functionService: FunctionService,
    private updateByService: UpdateByService,
  ) {
    this.observable = new BehaviorSubject<WebsiteVenue>(this.websiteVenue);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  setup(websiteVenue: WebsiteVenue) {
    if (!this.functionService.isEqual(this.websiteVenue, websiteVenue)) {
      this.websiteVenue = websiteVenue;
      this.observable.next(this.websiteVenue);
    }
  }

  getWebsiteVenue(): WebsiteVenue {
    if (this.websiteVenue) {
      if (!this.websiteVenue?.attachment) {
        this.websiteVenue.attachment = {};
      }
      // if (this.functionService.isUndefined(this.websiteVenue?.attachment?.dressCode)) {
      //   this.websiteVenue.attachment.dressCode = this.websiteAttachmentService.setupAttachment('dressCode');
      // }
      if (this.functionService.isUndefined(this.websiteVenue?.attachment?.parking)) {
        this.websiteVenue.attachment.parking = this.websiteAttachmentService.setupAttachment('parking');
      }
      if (this.functionService.isUndefined(this.websiteVenue?.attachment?.floorplan)) {
        this.websiteVenue.attachment.floorplan = this.websiteAttachmentService.setupAttachment('floorplan');
      }
      if (this.functionService.isUndefined(this.websiteVenue?.attachment?.menu)) {
        this.websiteVenue.attachment.menu = this.websiteAttachmentService.setupAttachment('menu');
      }
      return { ...this.websiteVenue };
    } else {
      return {
        title: this.getTitle(),
        msg: this.getMsg(),
        remark: '',
        enable: true,
        name: this.getVenueName(),
        address: this.getVenueAddress(),
        hall: this.getVenueHall(),
        gps: {
          appleMap: true,
          googleMap: this.checkGoogleMapCountry(),
          waze: this.checkWazeCountry(),
          uber: this.checkUberCountry(),
          grab: this.checkGrabCountry(),
        },
        attachment: {
          // dressCode: this.websiteAttachmentService.setupAttachment('dressCode'),
          parking: this.websiteAttachmentService.setupAttachment('parking'),
          floorplan: this.websiteAttachmentService.setupAttachment('floorplan'),
          menu: this.websiteAttachmentService.setupAttachment('menu'),
        },
        media: {
          url: '',
          type: '',
          mimeType: '',
        },
      };
    }
  }

  getTitle() {
    const title = this.accountEventModeService.replaceEventType(this.translate.instant('WEBSITE.venue.title'));
    return '<p class="ql-align-center"><span class="ql-size-large">' + title + '</span></p>';
  }

  getMsg() {
    return '';
  }

  getVenueName(name?: string) {
    if (!name) {
      name = this.accountInfoService.accountInfo?.location?.name ? this.accountInfoService.accountInfo.location.name : ' ';
    }
    
    return '<p class="ql-align-center"><span class="ql-size-large">' + (name ? name : ' ') + '</span></p>';
  }

  getVenueAddress(address?: string) {
    if (!address) {
      address = this.accountInfoService.accountInfo?.location?.address ? this.accountInfoService.accountInfo.location.address : ' ';
    }
    
    return '<p class="ql-align-center"><span class="ql-size-small">' + (address ? address : ' ') + '</span></p>';
  }

  getVenueHall(hall?: string) {
    if (!hall) {
      hall = '';
    }
    return '<p class="ql-align-center"><span class="ql-size-small">' + (hall ? hall : ' ') + '</span></p>';
  }

  /**
   * Check if uber is available in account country
   * @returns True if uber is available
   */
  checkUberCountry(): boolean {
    return this.visitorSettingService.checkUberCountry();
  }

  checkGrabCountry(): boolean {
    return this.visitorSettingService.checkGrabCountry();
  }

  /**
   * Check if google map is available in account country
   * @returns True if google map is available
   */
  checkGoogleMapCountry(): boolean {
    return this.visitorSettingService.checkGoogleMapCountry();
  }

  /**
   * Check if waze is available in account country
   * @returns True if waze is available
   */
  checkWazeCountry(): boolean {
    return this.visitorSettingService.checkWazeCountry();
  }

  async save(venue: WebsiteVenue) {
    if (this.accountId && !this.functionService.isEqual(venue, this.websiteVenue)) {
      venue.updateBy = this.updateByService.updateBy;
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ venue }, { merge: true });
      // if (this.websiteVenue?.enable !== venue?.enable) {
      //   const websiteContent = this.websiteContenstService.getWebsiteContent('venue');
      //   websiteContent.enable = venue.enable;
      //   this.websiteContenstService.saveWebsiteContent(websiteContent);
      // }
    }
  }
}
