import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { WebsiteDetailService } from './website-detail.service';
import { WebsiteLinksService } from './website-links.service';
import { WebsiteCheckinService } from './website-checkin.service';
import { WebsiteContactService } from './website-contact.service';
import { WebsiteContentsService } from './website-contents.service';
import { WebsiteFormService } from './website-form.service';
import { WebsiteGalleryService } from './website-gallery.service';
import { WebsiteHostService } from './website-host.service';
import { WebsiteInfoService } from './website-info.service';
import { WebsiteItineraryService } from './website-itinerary.service';
import { WebsiteQrcodeService } from './website-qrcode.service';
import { WebsiteThemeService } from './website-theme.service';
import { WebsiteTimeService } from './website-time.service';
import { WebsiteVenueService } from './website-venue.service';
import { WebsiteDressCodeService } from './website-dress-code.service';
import { WebsiteLinkService } from './website-link.service';


@Injectable({
  providedIn: 'root'
})
export class WebsiteSetupService implements OnInit, OnDestroy {

  constructor(
    private websiteDetailService: WebsiteDetailService,
    private websiteLinksService: WebsiteLinksService,

    private websiteInfoService: WebsiteInfoService,
    private websiteTimeService: WebsiteTimeService,
    private websiteCheckinService: WebsiteCheckinService,
    private websiteContactService: WebsiteContactService,
    private websiteGalleryService: WebsiteGalleryService,
    private websiteItineraryService: WebsiteItineraryService,
    private websiteHostService: WebsiteHostService,
    private websiteVenueService :WebsiteVenueService,
    private websiteQrcodeService: WebsiteQrcodeService,
    private websiteFormService: WebsiteFormService,
    private websiteContentsService: WebsiteContentsService,
    private websiteDressCodeService: WebsiteDressCodeService,
    private websiteThemeService :WebsiteThemeService,
    private websiteLinkService: WebsiteLinkService,
  ) { }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  async setup(accountId: string) {
    await this.websiteDetailService.setup(accountId);
    await this.websiteLinksService.setup(accountId);

    this.websiteInfoService.accountId = accountId;
    this.websiteTimeService.accountId = accountId;
    this.websiteCheckinService.accountId = accountId;
    this.websiteContactService.accountId = accountId;
    this.websiteGalleryService.accountId = accountId;
    this.websiteItineraryService.accountId = accountId;
    this.websiteHostService.accountId = accountId;
    this.websiteVenueService.accountId = accountId;
    this.websiteQrcodeService.accountId = accountId;
    this.websiteFormService.accountId = accountId;
    this.websiteContentsService.accountId = accountId;
    this.websiteThemeService.accountId = accountId;
    this.websiteDressCodeService.accountId = accountId;
    this.websiteLinkService.accountId = accountId;
  }
}
