import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WebsiteAttachment, WebsiteDetail } from 'src/app/interfaces/website';
import { FileService } from 'src/app/services/general/file.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { AttachmentType } from 'src/app/types/general';

@Component({
  selector: 'app-website-attachment',
  templateUrl: './website-attachment.component.html',
  styleUrls: ['./website-attachment.component.scss'],
})
export class WebsiteAttachmentComponent implements OnInit, OnDestroy {

  @Input() set setWebsiteAttachment(websiteAttachment: WebsiteAttachment) {
    this.websiteAttachment = websiteAttachment;
    this.msg = this.getContent(websiteAttachment?.msg);
  }
  
  @Input() attachmentType: AttachmentType;

  websiteAttachment: WebsiteAttachment;

  msg: string

  constructor(
    private fileService: FileService,
    private functionService: FunctionService,
  ) {

  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  getContent(msg: string) {
    if (msg) {
      if (!this.functionService.sanitizeHtml(msg).trim()) {
        return '';
      }
    }
    return msg;
  }
  
  openFile(attachment: WebsiteAttachment) {
    this.fileService.openFile(attachment?.attachmentUrl?.url, attachment.mimeType);
  }
}
