import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AccountInfo } from 'src/app/interfaces/account';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';
import { WebsiteCheckin, WebsiteDetail, WebsiteThemeColor } from 'src/app/interfaces/website';

import { getTime } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { PopupService } from 'src/app/services/general/popup.service';
import { DeviceService } from 'src/app/services/device/device.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { WebsiteTypeService } from 'src/app/services/website/website-type.service';
import { DateTimeService } from 'src/app/services/general/date-time.service';

@Component({
  selector: 'app-website-checkin',
  templateUrl: './website-checkin.component.html',
  styleUrls: ['./website-checkin.component.scss'],
})
export class WebsiteCheckinComponent  implements OnInit, OnDestroy {

  @Input() set setReady(ready: boolean) {
    if (ready) {
      this.setup();
    }
  }

  @Input() pageMode: boolean;
  @Input() previewMode: boolean;

  @Input() set setWebsiteDetail(websiteDetail: WebsiteDetail) {
    this.websiteDetail = websiteDetail;
    this.title = this.getContent(websiteDetail?.checkin?.title);
    this.msg = this.getContent(websiteDetail?.checkin?.msg);
    this.startMsg = this.getContent(websiteDetail?.checkin?.start?.msg);
    this.endMsg = this.getContent(websiteDetail?.checkin?.end?.msg);
  }
  
  @Input() websiteThemeColor: WebsiteThemeColor;

  @Input() accountInfo: AccountInfo;

  @Input() accountId: string;

  @Input() guest: Guest;
  @Input() group: Group;
  @Input() memberList: Guest[];

  @Input() checkinStatus: boolean;

  @Input() mediaHeight: number;

  @Input() updateTheme: boolean;

  websiteDetail: WebsiteDetail;
  title: string;
  msg: string;
  startMsg: string;
  endMsg: string;

  btn: string;

  name: string;
  seating: string;

  embededMode: boolean;

  // startTimeCountDown = {
  //   days: 0,
  //   hours: 0,
  //   mins: 0,
  // };

  // endTimeCountDown = {
  //   days: 0,
  //   hours: 0,
  //   mins: 0,
  // };

  startTime: number;
  endTime: number;
  now: number;
  // startTimeCountDown: number;
  // endTimeCountDown: number;
  qrcodeBtn: string;

  constructor(
    private fns: AngularFireFunctions,
    private translate: TranslateService,
    private websiteTypeService: WebsiteTypeService,
    private dateTimeService: DateTimeService,
    private deviceService: DeviceService,
    private functionService: FunctionService,
    private popupService: PopupService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  setup() {
    this.qrcodeBtn = this.getQrcodeBtn();
    if (this.websiteDetail?.checkin?.enable) {
      this.now = new Date().getTime() / 1000;
      this.setupStartTime();
      this.setupEndTime();
      this.setupStatus();
      if (this.websiteDetail?.checkin?.btn) {
        this.btn = this.websiteDetail.checkin.btn;
      } else {
        this.btn = this.translate.instant('WEBSITE.checkin.btn');
      }

      if (this.websiteTypeService.websiteType === 'home') {
        this.embededMode = true;
      } else {
        this.embededMode = false;
      }
    }
  }

  setupStartTime() {
    if (this.websiteDetail?.checkin?.start?.enable) {
      if (this.accountInfo?.time?.seconds && this.websiteDetail?.checkin?.start?.mins) {
        this.startTime = (((this.accountInfo.time.seconds + (this.websiteDetail.checkin.start.mins * 60)) * 1000)) / 1000;
      } else {
        this.startTime = 0;
      }
    } else {
      this.startTime = 0;
    }
  }

  setupEndTime() {
    if (this.websiteDetail?.checkin?.end?.enable) {
      if (this.accountInfo?.time?.seconds && this.websiteDetail?.checkin?.end?.mins) {
        this.endTime = (((this.accountInfo.time.seconds + (this.websiteDetail.checkin.end.mins * 60)) * 1000)) / 1000;
      } else {
        this.endTime = 0;
      }
    } else {
      this.endTime = 0;
    }
  }

  setupStatus() {
    if (this.accountId) {
      if (this.group?.groupId) {
        this.name = this.group.groupName;
        if (!this.checkinStatus) {
          this.checkinStatus = this.group?.status?.checkin ? true : false;
        }
        
        this.seating = this.memberList.map((x: Guest) => {
          return x.seating;
        }).join (', ');
      } else if (this.guest?.guestId) {
        this.name = this.guest.name;
        if (!this.checkinStatus) {
          this.checkinStatus = this.guest?.status?.checkin ? true : false;
        }
        
        this.seating = this.guest?.seating;
      }
    } else if (this.guest?.guestId === 'test') {
      this.name = this.translate.instant('WEBSITE.qrcode.guest.test');
    }
  }

  getContent(msg: string) {
    if (msg) {
      if (!this.functionService.sanitizeHtml(msg).trim()) {
        return '';
      }
    }
    return msg;
  }

  getQrcodeBtn() {
    if (this.websiteDetail?.qrcode?.btn) {
      return this.websiteDetail.qrcode.btn;
    }
    return this.translate.instant('WEBSITE.qrcode.btn');
  }

  toggleQrcodeMode() {
    this.websiteTypeService.setupWebsiteType('qrcode');
  }

  async checkin() {
    if (this.startTime > this.now) {
      const msg = this.websiteDetail?.checkin?.start?.msg ? this.functionService.sanitizeHtml(this.websiteDetail?.checkin?.start?.msg).trim() : this.translate.instant('WEBSITE.checkin.start.msg.msg');
      const date = this.dateTimeService.format(this.startTime * 1000, this.accountInfo?.dateFormat ? this.accountInfo.dateFormat : 'date', this.accountInfo?.language, this.accountInfo.timezone?.name, this.accountInfo?.locale, this.accountInfo?.location?.locality?.country);
      const time = this.dateTimeService.format(this.startTime * 1000, this.accountInfo?.timeFormat ? this.accountInfo.timeFormat : 'time', this.accountInfo?.language, this.accountInfo.timezone?.name, this.accountInfo?.locale, this.accountInfo?.location?.locality?.country);
      this.popupService.presentAlert(msg + (date ? '<br><br>' + date : '') + (time ? '<br>' + time : ''));
    } else if (this.endTime > this.now) {
      const msg = this.websiteDetail?.checkin?.end?.msg ? this.functionService.sanitizeHtml(this.websiteDetail?.checkin?.end?.msg).trim() : this.translate.instant('WEBSITE.checkin.end.msg.msg');
      const date = this.dateTimeService.format(this.endTime * 1000, this.accountInfo?.dateFormat ? this.accountInfo.dateFormat : 'date', this.accountInfo?.language, this.accountInfo.timezone?.name, this.accountInfo?.locale, this.accountInfo?.location?.locality?.country);
      const time = this.dateTimeService.format(this.endTime * 1000, this.accountInfo?.timeFormat ? this.accountInfo.timeFormat : 'time', this.accountInfo?.language, this.accountInfo.timezone?.name, this.accountInfo?.locale, this.accountInfo?.location?.locality?.country);
      this.popupService.presentAlert(msg + (date ? '<br><br>' + date : '') + (time ? '<br>' + time : ''));
    } else {
      if (this.accountId) {
        if (this.group?.groupId) {
          this.webCheckinCall();
        } else if (this.guest?.guestId) {
          this.webCheckinCall();
        }
      }
    }
  }

  async webCheckinCall() {
    await this.popupService.presentLoading();
    await this.fns.httpsCallable('webCheckinCall')({
      accountId: this.accountId,
      guestId: this.guest?.guestId,
      groupId: this.group?.groupId,
      uuid: this.deviceService.uuid,
    }).toPromise().then(async (response: any) => {
      await this.popupService.dismissLoading();
      if (response?.success) {
        // this.analyticsService.webCheckin(this.accountId);
        this.checkinStatus = true;
      } else {
        // if (response?.error === 'start') {
        //   await this.promptCheckinError('start', startTime);
        // } else if (response?.error === 'end') {
        //   await this.promptCheckinError('end', endTime);
        // } else if (response?.error === 'invalid') {
        //   await this.promptCheckinError('invalid', endTime);
        // } else {
        //   await this.promptCheckinError();
        // }
      }
    }).catch(async (err: any) => {
      // this.errorService.logError(err);
      await this.popupService.dismissLoading();
      // await this.promptCheckinError();
    });
  }

}
